import axios from '../api/axios'
import React from 'react'
import { useEffect } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

const baseUrl = process.env.REACT_APP_API_URL;

const Reddit = () => {

    const callbackUrl = `${baseUrl}/integrations/redirect/reddit`

    // const [isSuccess, setIsSuccess] = useState(false)

    
    // const openRedditAuth = () => {
    //     window.open(
    //         `https://www.reddit.com/api/v1/authorize?client_id=clZ_PJYPkLUKTSCd_kEsYw&response_type=code&state=RANDOM_STRING&redirect_uri=${callbackUrl}&duration=permanent&scope=identity+history+save`, "_self"
    //     )
    // }

    let search = window.location.search;
    let params = new URLSearchParams(search);

    const location = useLocation();
    console.log(location)

    const [searchParams, setSearchParams] = useSearchParams();
    let foo = searchParams.get("code")

    const submitToReddit = async (foo) => {
        const redditSubmit = await axios.post('/integrations/add/reddit', {code: foo}).then(res => res.data)
        console.log("SUBMITTING")
        if (redditSubmit.status === 'success') {
            window.open(
                `${baseUrl}/#/settings`, '_self'
            )
        }

        console.log(redditSubmit.status)
        // console.log({redditSubmit})
        return redditSubmit
    }

    submitToReddit(foo)

    // useEffect(() => {
    //     let search = window.location.search;
    //     let params = new URLSearchParams(search);
    //     let foo = params.get('code');
    //     if (foo) {
    //         const res = submitToReddit(foo)
    //         // console.log(res)    
            
    //     }
    // }, [])
    

    return (
        <div className='w-full h-full flex justify-center items-center flex-col'>
            {/* <button onClick={openRedditAuth} className='m-20 p-1 cursor-pointer rounded-lg bg-orange-400 text-white'>Login Reddit</button>
            <button onClick={() => console.log("foo")} className='m-20 p-1 cursor-pointer rounded-lg bg-orange-400 text-white'>Check</button> */}

        </div>
    )
}

export default Reddit