import {React, useContext} from 'react'
import { createSearchParams, generatePath, Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { useAddBoomark } from '../../hooks/useBookmarkData';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";
import { ListSettingsModal } from '../ListSettingsModal';
import toast, { Toaster } from 'react-hot-toast';
import ModalContext from '../../context/ModalContext';
import useIsMobile from '../Hooks/useIsMobile';
import { SidebarContext } from './Layout';







function Header({pageTitle, isFetching, refetch, listInfo, listId, list, listData}) {


    const {list: listContext} = useContext(ModalContext)
    const [selectedList, setSelectedList] = listContext;

    const isMobile = useIsMobile();

    const {sidebar, setShowSidebar} = useContext(SidebarContext);

    const handleSidebar = () => {
        setShowSidebar(true);
    }


    return (
        <div className='titlebar sticky top-0 backdrop-blur-sm bg-white/80  z-[8]'>
            <div className='w-full flex flex-row draggable h-16 border-b relative ' >
                <div className={`flex flex-row items-center ${isMobile ? 'px-[15px]' : 'px-[35px]'} font-bold text-xl justify-between w-full`}>
                    <div className='flex flex-row items-center gap-2'>
                        {isMobile && 
                            <div onClick={handleSidebar}>
                                
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"/><line x1="3" y1="6" x2="21" y2="6"/><line x1="3" y1="18" x2="21" y2="18"/></svg>
                        
                            </div>}
                        {list && <div className={`w-5 h-5 rounded bg-${selectedList.listColour}-400 `}></div>}
                        {pageTitle}
                    </div>
                    <div className='flex flex-row items-center gap-4'>
                        <AddBookmarkPopover />
                        
                        <div className={`${isFetching && 'animate-spin'} hover:cursor-pointer`} onClick={refetch}>
                            <svg class="h-[22px] font-medium" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-width="2" stroke="currentColor" fill="none" stroke-linejoin="round"><path opacity=".15" d="M2 12c0 2.25.74 4.32 2 6m18-6.01c0-2.26-.74-4.33-1.99-6"></path><path d="M2 7.5V4m0 3.5C2 7.5 5.33 2 12 2c3.29 0 6.19 1.57 8.01 4M1.99 7.5h3.5m15.5 9s-3.48 5.5-9 5.5c-3.28 0-6.18-1.58-8-4m17-1.51h-3.5m3.5 0v3.5"></path></g></svg>
                        </div> 
                        {list && 
                            <ListSettingsModal listId={listId} listTitle={pageTitle} listColour={selectedList.listColour}>
                                <svg class="h-[23px] font-medium text-palette-900 transition-all duration-300 active:text-palette-500 smarthover:hover:text-palette-500" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M6 5V2m0 20v-6m12 6v-3m0-11V2m-.8 17h1.6c1.12 0 1.68 0 2.1-.22 .37-.2.68-.5.87-.88 .21-.43.21-.99.21-2.11v-1.6c0-1.13 0-1.69-.22-2.11 -.2-.38-.5-.69-.88-.88 -.43-.22-.99-.22-2.11-.22h-1.6c-1.13 0-1.69 0-2.11.21 -.38.19-.69.49-.88.87 -.22.42-.22.98-.22 2.1v1.6c0 1.12 0 1.68.21 2.1 .19.37.49.68.87.87 .42.21.98.21 2.1.21Zm-12-6h1.6c1.12 0 1.68 0 2.1-.22 .37-.2.68-.5.87-.88 .21-.43.21-.99.21-2.11v-1.6c0-1.13 0-1.69-.22-2.11 -.2-.38-.5-.69-.88-.88 -.43-.22-.99-.22-2.11-.22h-1.6c-1.13 0-1.69 0-2.11.21 -.38.19-.69.49-.88.87 -.22.42-.22.98-.22 2.1v1.6c0 1.12 0 1.68.21 2.1 .19.37.49.68.87.87 .42.21.98.21 2.1.21Z"></path></svg>
                            </ListSettingsModal>
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

const AuthDropdown = () => {


    const navigate = useNavigate();

    const session = useSessionContext();


    const handleLogOut = async () => {
        await signOut()
        navigate("/")
    }


    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger>
                <div className='bg-white rounded-full px-4 py-2 search-shadow border-gray-200 border items-center flex flex-row'>
                    <button className=''>
                        {session.accessTokenPayload.email}
                    </button>

                    {/* <FontAwesomeIcon icon={faCaretDown} className='w-2' /> */}
                </div>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
            <DropdownMenu.Content align='end' className='z-[60] fucker rounded-lg' sideOffset={0}>
                <div className='bg-white text-sm p-2 w-[10rem] flex gap-1 flex-col outline-none rounded-xl'>
                    <DropdownMenu.Item className='p-1 outline-none hover:text-blue-500'>
                    <Link
                                                    to="/settings"    
                                                >
                        Settings
                        </Link>
                    </DropdownMenu.Item>
                    <DropdownMenu.Separator className='h-[1px] bg-gray-200'/>
                    <DropdownMenu.Item onSelect={handleLogOut} className='outline-none cursor-pointer hover:text-blue-500 p-1'>
                        Logout
                    </DropdownMenu.Item>
                </div>
                <DropdownMenu.Arrow className="DropdownMenuArrow fill-white" />


            </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}

const AddBookmarkPopover = () => {

    const [URL, setURL] = useState('')
    


    let {type} = useParams()

    const {mutate: addBookmark} = useAddBoomark(type)
    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log(URL)
        await addBookmark(URL)
        setURL('')
    }   

    return (
        <div className=''>
            <Popover.Root>
                <Popover.Trigger asChild>
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-[30px]">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                                    </svg>
                    </div>
                </Popover.Trigger>
                <Popover.Anchor />
                <Popover.Portal >
                <Popover.Content sideOffset={0} align='end' className='z-[60]'>
                    
                    <div className='fucker bg-white w-[25rem] h-full p-4 flex flex-col gap-2 z-[60] rounded-lg'>
                    <div className='font-bold'>
                        Add bookmark
                    </div>
                    <div className='flex flex-row gap-2'>
                        <form onSubmit={handleSubmit} className='flex-row flex w-full'>
                            <input type="text" placeholder='https://' className='w-full p-1 rounded-md ring-black bg-gray-100 focus:outline-none' 
                                    value={URL} onChange={(event) => setURL(event.target.value)}/>                                {/* <button type="submit" className='rounded text-white bg-green-500'>Submit</button> */}
                        </form>
                        <div className='flex flex-row justify-end'>
                            <button className='border px-2 py-1 rounded'>
                                Save
                            </button>
                        </div>

                    </div>
                    </div>
                    <Popover.Close />
                    <Popover.Arrow className="PopoverArrow fill-white" />

                </Popover.Content>
                </Popover.Portal>
            </Popover.Root>

        </div>
    )
}

export default Header