import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../../api/axios'
import { useAddNote, useBookmarkData, useBookmarkIndividual, useBookmarkSearch } from '../../hooks/useBookmarkData'
import useDebounce from '../../hooks/useDebounce'
import { useCallback } from 'react'
import { BookmarkCard } from '../../components/BookmarkCard/BookmarkCard'
import MasonryLayout from '../../components/MasonryLayout'
import { MyDropzone } from '../../components/MyDropzone'
import Header from '../../components/layout/Header'
import {useDrag, useDrop} from '@react-aria/dnd'

import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {useSortable} from '@dnd-kit/sortable';
import Main from '../../components/layout/Main'

export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  
  
  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      {props.id}
    </div>
  );
}



function Test() {
  const [items, setItems] = useState([1, 2, 3]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext 
        items={items}
        strategy={verticalListSortingStrategy}
      >
        {items.map(id => <SortableItem key={id} id={id} />)}
      </SortableContext>
    </DndContext>
  );
  
  function handleDragEnd(event) {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
}


function DropTarget() {
    let [dropped, setDropped] = React.useState(null);
    let ref = React.useRef();
    let { dropProps, isDropTarget } = useDrop({
      ref,
      async onDrop(e) {
        let items = await Promise.all(
          e.items
            .filter((item) =>
              item.kind === 'text' &&
              (item.types.has('text/plain') ||
                item.types.has('my-app-custom-type'))
            )
            .map(async (item) => {
              if (item.types.has('my-app-custom-type')) {
                return JSON.parse(await item.getText('my-app-custom-type'));
              } else {
                return { message: await item.getText('text/plain') };
              }
            })
        );
        setDropped(items);
      }
    });
  
    let message = ['Drop here'];
    if (dropped) {
      message = dropped.map((d) => {
        let message = d.message;
        if (d.style === 'bold') {
          message = <strong>{message}</strong>;
        } else if (d.style === 'italic') {
          message = <em>{message}</em>;
        }
        return <div>{message}</div>;
      });
    }
  
    return (
      <div
        {...dropProps}
        role="button"
        tabIndex={0}
        ref={ref}
        className={`droppable ${isDropTarget ? 'target' : ''}`}
      >
        {message}
      </div>
    );
  }
  

function Draggable() {
    let { dragProps, isDragging } = useDrag({
        getItems() {
          return [{
            'text/plain': 'fuck'
          }];
        }
      });
    
      return (
        <div
          {...dragProps}
          role="button"
          tabIndex={0}
          className={`draggable text-3xl bg-slate-200 w-full ${isDragging ? 'dragging' : ''}`}
        >
          Drag me
        </div>
      );
}

export const Library = ({ lists}) => {

    
    

    const queryClient = useQueryClient();
    const {refetch, isLoading, isError, error, data, isFetching: isFetchingAllBookmarks} = useQuery(['library'], () => 
    axios
        .get(`/bookmarks`)
        .then(res => {
            console.log(res.data)
            return res.data
        }), {
            refetchOnWindowFocus: true
        }
    )

    const prefetch = () => {

        console.log("Prefetching")

        queryClient.prefetchQuery(['articles'], () => 
        axios
            .get(`/bookmarks/articles`)
            .then(res => {
                console.log(res.data)
                return res.data
            }))
    }

    useEffect(() => {
        prefetch()
    }, [])

    if (isLoading) {
        return (
            <>
                <Header pageTitle='library' refetch={refetch} isFetching={isFetchingAllBookmarks} />
            </>
        )
    }
    
    if (data) {
        return (
            <>
                {/* <button onClick={() => refetch()}>Click</button> */}
                <Header pageTitle='library' refetch={refetch} isFetching={isFetchingAllBookmarks} />
                <Main>
                    <MyDropzone>
                        <div className='w-full z-[9]'>
                            <MasonryLayout data={data} lists={lists} />       
                        </div>
                    </MyDropzone>
                </Main>
            </>
        )
    }

    
    

    
    


   
    
}
