import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { useDrop } from "@react-aria/dnd";
import { toast } from "react-hot-toast";
import ModalContext from "../context/ModalContext";
import { useAddToList } from "../hooks/useBookmarkData";
import axios from "../api/axios";
import { ListContextMenu } from "./ListContextMenu";

const reorder = (list, startIndex, endIndex) => {
  const result = list;
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  console.log(result);
  return result;
};

const ListOfLists = ({ removeSearch }) => {
  const [state, setState] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);

  const [objects, setObjects] = useState({});

  const [list, setList] = useState();

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newArray = reorder(
      lists,
      result.source.index,
      result.destination.index
    );

    // Don't have to pass newArray around, was just checking to see if it would update faster if I did
    mutation.mutate({
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
      newArray: newArray,
    });
  }

  const { data: lists } = useQuery(["lists"], () =>
    axios.get("/lists/all").then((res) => {
      console.log(res.data);
      return res.data;
    })
  );

  const queryClient = useQueryClient();

  const updateList = ({ sourceIndex, destinationIndex, newArray }) => {
    return axios
      .put("/lists/sort", {
        sourceIndex: sourceIndex,
        destinationIndex: destinationIndex,
      })
      .then((res) => {
        console.log("res.data");
        return res.data;
      });
  };

  const mutation = useMutation({
    mutationFn: updateList,
    onMutate: (variables) => {
      console.log("wow");

      console.log(variables);
      const { sourceIndex, destinationIndex, newArray } = variables;

      // const currentListsOrder = queryClient.getQueryData(['lists'])

      // const newArray = reorder(
      //   currentListsOrder,
      //   sourceIndex,
      //   destinationIndex
      // );

      queryClient.setQueryData(["lists"], newArray);
    },
    onSuccess: (data) => {
      console.log("UPDATED");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["lists"] });
    },
  });

  if (lists) {
    return (
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Fuck state={lists} removeSearch={removeSearch} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
};

const ListItem = ({ item: list, index }) => {
  let listId = list.list_id;
  let listName = list.list_name;
  let listColour = list.list_colour;

  const { mutate: addTooList } = useAddToList();

  const addToList = (listId, bookmarkId) => {
    axios
      .post("/lists/add", {
        listId: listId,
        bookmarkId: bookmarkId,
      })
      .then((res) => {
        if (res.status === 201) {
          toast.success("Bookmark added to list", {
            position: "top-right",
          });
        }
      });
  };

  let [dropped, setDropped] = useState(null);
  let ref = useRef();

  let { dropProps, isDropTarget } = useDrop({
    ref,
    async onDrop(e) {
      let item = e.items.filter(
        (item) => item.kind === "text" && item.types.has("bookmarkId")
      );

      let bookmarkId = await item[0].getText("bookmarkId").then((data) => data);
      addTooList({
        listId: listId,
        bookmarkId: bookmarkId,
      });

      setDropped(item.join("\n"));
    },
  });

  const { list: listContext } = useContext(ModalContext);

  const [, setSelectedList] = listContext;

  return (
    <ListContextMenu listId={listId} listName={listName}>
      <div
        {...dropProps}
        role="button"
        tabIndex={0}
        ref={ref}
        className={`droppable ${isDropTarget ? "target" : ""}`}
      >
        <div
          onClick={() =>
            setSelectedList({ listId, listColour, listTitle: list.list_name })
          }
          className={`flex flex-row items-center gap-4 px-2 py-1 rounded-xl ${isDropTarget && "bg-gray-100"
            }`}
        >
          <div className={`w-2 h-2 rounded-sm bg-${listColour}-400`}></div>
          {list.list_name}
        </div>
      </div>
    </ListContextMenu>
  );
};

const Fuck = ({ state, removeSearch }) => {
  const listName = state.list_name;
  const listId = state.list_id;

  return state.map((item, index) => {
    return (
      <Draggable draggableId={`id-${index}`} index={index} key={index}>
        {(provided) => (
          <div
            onClick={() => console.log(item.list_id)}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div key={item.list_id} onClick={removeSearch}>
              <Link
                to={`/list/${item.list_id}`}
                state={{
                  listTitle: item.list_name,
                  listId: item.list_id,
                  listColour: item.list_colour,
                }}
              >
                <ListItem item={item} index={index} />
              </Link>
            </div>

            {/* {item.list_name + ': ' + index} */}
          </div>
        )}
      </Draggable>
    );
  });
};

export default ListOfLists;
