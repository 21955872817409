import './index.css';
import Homepage from './components/Homepage'
import {
  HashRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from 'react-router-dom'
import * as reactRouterDom from "react-router-dom";
import SuperTokens, { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import ThirdPartyPasswordless from "supertokens-auth-react/recipe/thirdpartypasswordless";
import Session, { SessionAuth, useSessionContext } from "supertokens-auth-react/recipe/session";
import Reddit from './components/Reddit';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Library } from './pages/Library/Library';
import { Articles } from './pages/Articles/Articles';
import { Settings } from './pages/Settings/Settings';
import Search from './pages/Search/Search';
import List from './pages/List/List';
import axios from './api/axios';
import { Home } from './pages/Home/Home';
import { MyDropzone } from './components/MyDropzone';
import ModalContext from './context/ModalContext';
import Layout from './components/layout/Layout';
import { BookmarkModal } from './components/BookmarkModal';
import { Videos } from './pages/Videos';
import { Websites } from './pages/Websites';
import { Twitter } from './pages/Twitter';
import Lists from './pages/Lists/Lists';
import TestPage from './pages/TestPage/TestPage';
import Tags from './pages/Tags/Tags';









function App() {

  const session = useSessionContext();

  console.log(session.doesSessionExist)

  const {data: lists} = useQuery(['lists'], () => 
  axios
      .get('/lists/all')
      .then(res => {
          console.log(res.data)
          return res.data
      })
)

  // console.log('heyeyeyeyye', window?.envVars?.myEnvironmentVariable)
  const [showModal, setShowModal] = useState(false);
  const [selectedBookmark, setSelectedBookmark] = useState('')
  const [selectedList, setSelectedList] = useState('')

  return (
    <div className='w-full h-full'>
        <Router className='flex flex-col h-full justify-between'>
          <ModalContext.Provider value={{ modal: [showModal, setShowModal], selected: [selectedBookmark, setSelectedBookmark], list: [selectedList, setSelectedList]}}>
            <Routes>
                {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
                <Route exact path="/" element={session.doesSessionExist ? <Navigate to="/library"/> : <Homepage className='grow'/>} />
                  <Route path="/moodleapi" element={<TestPage />} />
                  <Route element={
                    <SessionAuth>
                      <BookmarkModal />
                      <Layout />
                    </SessionAuth>
                  } >
                    
                    <Route path="/library" element={
                          <MyDropzone>
                            <Library lists={lists}/>
                          </MyDropzone>
                    } />

                    <Route path="/articles" element={
                          <Articles lists={lists}/>
                    } />

                     <Route path="/tags" element={
                          <Tags lists={lists}/>
                    } />

                    <Route path="/videos" element={
                          <Videos lists={lists}/>
                    } />  

                    <Route path="/websites" element={
                          <Websites lists={lists}/>
                    } /> 

                    <Route path="/lists" element={
                          <Lists />
                    } /> 

                    <Route path="/twitter" element={
                          <Twitter lists={lists}/>
                    } /> 

                    <Route path="/settings" element={
                          <Settings />
                    } /> 

                    <Route path="/search" element={
                          <Search />
                    } />

                    <Route path="/list/:listId" element={
                          <List lists={lists}/>
                    } />

                    <Route path="/home" element={
                          <Home lists={lists} />
                    } />
                  </Route>

                  <Route exact path="/reddit" element={
                    <SessionAuth>
                      <Reddit />
                    </SessionAuth>
                  }/>
                
            </Routes>
          </ModalContext.Provider>


          {/* <Footer /> */}

        </Router>

    </div>
  );
}

export default App;
