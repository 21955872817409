import { useContext, useEffect, useRef, useState } from 'react';
import ModalContext from '../../context/ModalContext.jsx';
import { BookmarkArticle, BookmarkHackernews, BookmarkImage, BookmarkPending, BookmarkProduct, BookmarkReddit, BookmarkStackoverflow, BookmarkTiktokVideo, BookmarkTwitter, BookmarkVideo, BookmarkWebsite, ContextMen, DialogModal } from './CardTypes.jsx'
import { ContextMenu } from '../ContextMenu.jsx';
import { DragPreview, useDrag } from '@react-aria/dnd';
import { useImage } from '../../hooks/useImage.js';


export const BookmarkCardForList = ({bookmark}) => {

    const type = bookmark.type;
    const bookmarkId = bookmark.bookmark_id
    const title = bookmark?.type_data?.title || bookmark?.type_data?.originalName || `by ${bookmark?.type_data?.authorName}` || ''
    const content = CardContent(bookmark, type)
    const url = bookmark.url

    const ref = useRef(null)
    const [height, setHeight] = useState(0)

    useEffect(() => {

        const currentHeight = ref.current.offsetHeight;
        setHeight(currentHeight);

        const scaleFactor = 100 / currentHeight

        if (currentHeight > 100) {
            ref.current.style.transform = `scale(${scaleFactor})`
            if (currentHeight > 450) {
                ref.current.style.display = "none"
            }
            // ref.current.style.height = ref.current.offsetHeight
        }

    })

    return (      
        <div key={bookmark.bookmark_id} className="w-full cursor-pointer list-stack z-[10] " ref={ref
        }  
        >
            <div className={`
                relative w-full rounded-lg border
                ${(type === 'Reddit' || type === 'twitter') && 'max-h-[45rem] p-4'}
                ${(type === 'website' || type === 'article' || type === 'product' || type === 'video' || type === 'image') && 'p-2'}
            `}>
                {content}
            </div>
        </div>
    )
}

export const DemoBookmarkCard = ({bookmark}) => {

    const type = bookmark.type;
    const bookmarkId = bookmark.bookmark_id
    const title = bookmark?.type_data?.title || bookmark?.type_data?.originalName || `by ${bookmark?.type_data?.authorName}` || ''
    const content = CardContent(bookmark, type)
    const url = bookmark.url

    

    return (      
        <div key={bookmark.bookmark_id} className="w-full cursor-pointer"
        >
            <div className={`
                relative w-full rounded-lg border
                ${(type === 'Reddit' || type === 'twitter') && 'max-h-[45rem] p-4 bg-white'}
                ${(type === 'website' || type === 'article' || type === 'product' || type === 'video' || type === 'image') && 'p-2'}
            `}>
                {content}
                <HoverBorder />
            </div>
            <div className='text-[#748297] text-center text-xs mt-2'>{title && title}</div>
        </div>
    )
}

export const BookmarkCard = ({ data: bookmark, lists, hideTitle = false }) => {

    // const {isLoading, isError, error, data, isFetching} = useImage(bookmark.type_data.thumbnail_key)

    const {modal, selected} = useContext(ModalContext)
    
    const [, setShowModal] = modal;
    const [, setSelectedBookmark] = selected

    const type = bookmark.type;
    const bookmarkId = bookmark.bookmark_id
    const title = bookmark?.type_data?.title || bookmark?.type_data?.originalName || `by ${bookmark?.type_data?.authorName}` || ''
    const content = CardContent(bookmark, type)
    const url = bookmark.url

    let { dragProps, isDragging } = useDrag({
        getItems() {
            return [{
                'text/plain': url,
                'bookmarkId': bookmarkId
            }];
        }
    });

    return (
        <ContextMenu bookmarkId={bookmarkId} url={url} lists={lists} key={bookmarkId} >
            <div className='w-full'>
                <div
                    {...dragProps}
                    role="button"
                    tabIndex={0}
                >
                    <div key={bookmark.bookmark_id} className="w-full cursor-pointer" onClick={() => {
                        setSelectedBookmark(bookmark)
                        setShowModal(true)
                    }}>
                        <div className={`
                            relative damn w-full rounded-lg border bg-white
                            ${(type === 'Reddit' || type === 'twitter') && 'max-h-[45rem] p-4'}
                            ${(type === 'website' || type === 'article' || type === 'product' || type === 'video' || type === 'image') && 'p-2'}
                        `}>
                            {content}
                            <HoverBorder />
                        </div>
                        {/* <DragPreview ref={preview}>
                        {(items) => (
                            <div className='w-40 border text-center rounded-lg p-2 bg-white'>
                                {bookmark.site_name} - {title}
                            </div>
                        )}
                        
                </DragPreview> */}
                        { !hideTitle && <div className='text-[#748297] text-center text-xs mt-2'>{title && title}</div>}
                    </div>
                </div>
            </div>
        </ContextMenu>
    )

}

const CardContent = (bookmark, type) => {
    switch (type) {
        case 'stackoverflow':
            return <BookmarkStackoverflow bookmark={bookmark}/>
        case 'website':
            return <BookmarkWebsite bookmark={bookmark} />
        case 'twitter':
            return <BookmarkTwitter bookmark={bookmark} />
        case 'article':
            return <BookmarkArticle bookmark={bookmark} />
        case 'product':
            return <BookmarkProduct bookmark={bookmark} />
        case 'video':
            return <BookmarkVideo bookmark={bookmark} />
        case 'new':
            return <BookmarkPending bookmark={bookmark} />
        case 'image':
            return <BookmarkImage bookmark={bookmark} />
        case 'TikTok':
            return <BookmarkTiktokVideo bookmark={bookmark} />
        case 'Reddit':
            return <BookmarkReddit bookmark={bookmark} />
        case 'note':
            return <div className='p-3 text-xs rounded-lg'>
            <div className='bg-white p-2 rounded'>
                <div className='inline bg-yellow-100'>
                    {bookmark.type_data.note}
                </div>
            </div>
        </div>
        case 'Hackernews':
            return <BookmarkHackernews bookmark={bookmark} />
        case 'pending':
            return <div className=''></div>
        case 'test':
            return <div></div> 
    }
}


const HoverBorder = () => (
    <div className='shit flex flex-row justify-end items-end opacity-0 hover:opacity-100 absolute top-0 left-0 w-full h-full border-[1px] rounded-lg border-[#b8c3d3] transition-opacity'>
                        {/* <div className=' p-1 bg-white fuck rounded-tl-md rounded-br-lg overflow-hidden flex flex-row items-center gap-1'>
                            <svg className='w-3 h-3 text-black' viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 7V1H15" stroke="#B6B6B6" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21 1L7.66663 14.3333" stroke="#B6B6B6" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.3333 5H2.33333C1.59695 5 1 5.59695 1 6.33333V19.6667C1 20.403 1.59695 21 2.33333 21H15.6667C16.403 21 17 20.403 17 19.6667V11.6667" stroke="#B6B6B6" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <a href={bookmark.url} target='_blank' rel='noreferrer' className='uppercase tracking-widest text-sm underline'>reddit</a>
                        </div> */}
    </div>
)