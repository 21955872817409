import { Root, Trigger, Portal, Content, Item, Sub, SubTrigger, SubContent } from '@radix-ui/react-context-menu';
import { useQuery } from '@tanstack/react-query';
import axios from '../api/axios';
import { useDeleteBookmark } from '../hooks/useBookmarkData';
import toast, { Toaster } from 'react-hot-toast';

// TODO: Add option to remove bookmark from list (if in a list page)

export const ContextMenu = ({children, bookmarkId, url}) => {

   

    const item = 'flex items-center relative hover:bg-gray-50 p-2 rounded-lg'


    const {data: lists} = useQuery(['lists'], () => 
        axios
            .get('/lists/all')
            .then(res => {
                console.log(res.data)
                return res.data
            })
    )


    const addToList = (listId, bookmarkId) => {
        axios.post('/lists/add', {
            listId: listId,
            bookmarkId: bookmarkId
        }).then((res) => {
            if (res.status === 201) {
                toast.success("Bookmark added to list", {
                    position: 'top-right'
                })
            }
        })
    }

    const {mutate: removeBookmark} = useDeleteBookmark()
    const handleDelete = async () => {
        removeBookmark(bookmarkId)
    }   

    const handleCopyLink = () => {
        navigator.clipboard.writeText(url)
    }

    const handleOpenLink = () => {

    }

    return (
        <Root>
            <Trigger asChild={true}>
                {children}
            </Trigger>
            <Portal>
                <Content className='ContextMenuContent bg-white p-2 rounded-lg border context-menu-shadow z-[90] w-56'>
                    <Item className={`${item}`} onClick={() => {
                        handleCopyLink()
                    }}>
                        Copy Link
                    </Item>
                    <Item asChild={true} className={`${item}`}>
                        <a href={url} target="_blank">
                            Open Link in New Tab    
                        </a> 
                    </Item>
                    <Sub>
                        <SubTrigger className={`${item}`}>
                            Add to List
                            <div className='RightSlot'>
                                +
                            </div>
                        </SubTrigger>
                        <Portal>
                            <SubContent className='border bg-white p-2 rounded-lg context-menu-shadow min-w-[220px] z-[91]' sideOffset={2} alignOffset={5}>
                                <div className='max-h-60 overflow-auto'>
                                    {lists && lists.map((item, index) => {

                                        return (
                                            <Item onClick={() => addToList(item.list_id, bookmarkId)} className={`flex items-center relative hover:bg-gray-50 p-2 rounded-lg`}>
                                                <button>
                                                    {item.list_name}
                                                </button>
                                            </Item>
                                        )
                                    })}

                                </div>
                            </SubContent>
                        </Portal>
                    </Sub>
                    <Item className={`${item} text-red-400`} onClick={() => {
                        handleDelete()
                    }}>
                        Delete Bookmark
                    </Item>
                </Content>
            </Portal>
        </Root>
    )
}
