import * as Dialog from '@radix-ui/react-dialog';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import axios from '../api/axios';
import ModalContext from '../context/ModalContext';
import { useAddTag, useBookmarkTags, useDeleteBookmark } from '../hooks/useBookmarkData';
import { useImage } from '../hooks/useImage';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

export const ListSettingsModal = ({ children }) => {

    const queryClient = useQueryClient()

    const {list: listContext} = useContext(ModalContext)
    const [selectedList, setSelectedList] = listContext;

    const [listName, setListName] = useState(selectedList.listTitle);
    const [selectedColour, setSelectedColour] = useState('')
    const [listId, setListId] = useState('')


    useEffect(() => {
        setListName(selectedList.listTitle)
        setSelectedColour(selectedList.listColour)
        setListId(selectedList.listId)
    }, [selectedList])

    const {listColour} = selectedList


    const handleChange = (event) => {
        setListName(event.target.value)
    }

    const handleSubmit = async () => {
        console.log("Wow")
        await axios.put(
            `/lists/${listId}`,
            {
                listName: listName,
                listColour: selectedColour
            }
        ).then((res) => {
            if (res.status === 200) {
                toast.success(`List changes successfully made`, {
                    position: 'top-right'
                })
                setSelectedList({
                    ...selectedList,
                    listTitle: listName,
                    listColour: selectedColour
                })
            }
        })

        queryClient.invalidateQueries({queryKey: ['list', listId]})
        queryClient.invalidateQueries({queryKey: ['lists']})
    }
    
    // bg-lime-400 bg-amber-400 bg-orange-400 bg-green-400 bg-teal-400 bg-cyan-400 bg-sky-400 bg-blue-400 bg-violet-400 bg-fuchsia-400 bg-pink-400 bg-rose-400 bg-stone-400
    const colors = ["lime", "amber", "orange", "green", "teal", "cyan", "sky", "blue", "violet", "fuchsia", "pink", "rose"];
    


    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>
                {children}
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className='bg-black bg-opacity-[0.2] transition-shadow fixed inset-0 z-40 DialogOverlay'>
                    <Dialog.Content className='DialogContent fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4'>
                        <div className='flex flex-col gap-6 bg-white px-8 py-5 pt-6 rounded-lg w-[25rem]'>
                            <div className='text-xl font-bold items-center flex flex-row'>
                                <p className='w-2/3'>
                                    Edit List
                                </p>
                                <div className='flex justify-end w-1/3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <div className='flex flex-col items-start gap-2'>
                                <p className=''>List title</p>
                                <input className='w-full border rounded-lg p-2' type="text" value={listName} onChange={handleChange} />
                            </div>
                            <div className='flex gap-2 flex-col'>
                                <p>Icon colour</p>
                                <div className='flex flex-row gap-2 flex-wrap'>
                                    
                                    {colors.map((colour, index) => {
                                        return <ColourOption colour={colour} selectedColour={selectedColour} setSelectedColour={setSelectedColour} />
                                    })}


                                </div>
                            </div>
                            <div className='flex flex-row justify-end gap-4'>
                                <button className='border rounded-lg p-2' onClick={handleSubmit}>Cancel</button>
                                <button className='border rounded-lg p-2' onClick={() =>     console.log(selectedList)
}>Save</button>
                            </div>
                        </div>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}


const ColourOption = ({ colour, selectedColour, setSelectedColour }) => {

    let isSelected = colour === selectedColour
    // console.log({colour, selectedColour})
    return (
        <div className={`w-8 h-8 flex items-center justify-center ${isSelected && 'border-2 border  rounded-lg border-black'}`}>
            <div className={`bg-${colour}-400 w-6 h-6 rounded-lg`} onClick={() => setSelectedColour(colour)}></div>
        </div>
    )
}
