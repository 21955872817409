import React from 'react'
import useIsMobile from '../Hooks/useIsMobile';

const Main = ({children}) => {

    const isMobile = useIsMobile();

    return (
        <div className={`w-full ${isMobile ? 'p-[15px]' : 'p-[35px]'}  flex flex-col h-full justify-center`}>
            <div className='w-full'>
                {children}       
            </div>
        </div>
    )
}

export default Main