import React from 'react'
import { useSearchParams } from 'react-router-dom';
import Header from '../../components/layout/Header';
import MasonryLayout from '../../components/MasonryLayout';
import { useBookmarkSearch } from '../../hooks/useBookmarkData';
import useDebounce from '../../hooks/useDebounce';
import Main from '../../components/layout/Main';

const Search = ({ setSelectedBookmark, setShowModal, param, lists}) => {


    let [searchParams, setSearchParams] = useSearchParams();
    let search = searchParams.get('query')

    let searchInput = useDebounce(search, 250)
    const {data: searchResult, isFetching: isFetchingSearchResult, isLoading: shiit, isPreviousData} = useBookmarkSearch(searchInput)


    if (isFetchingSearchResult) {
        return (
            <>
                <Header pageTitle={`results for "${search}"`} isFetching={isFetchingSearchResult} />
                {/* <div className='w-full h-full flex items-center justify-center'>
                    <div type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm rounded-md text-indigo-500 " disabled="">
                                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                </div> */}
            </>
        )
    }


    if (searchResult && searchResult.length === 0 && searchInput) {
        return (
            <>
            <Header pageTitle={`results for "${search}"`} />
            <div className='w-full flex flex-col gap-4 justify-center items-center text-center h-[calc(100vh-170px)]'>
                <svg class="h-14 text-palette-800" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-width="2" stroke="currentColor" fill="none" stroke-linejoin="round"><path opacity=".3" d="M7.5 3.38c1.28-.88 2.83-1.39 4.5-1.39 4.41 0 8 3.58 8 8 0 1.52-.58 2.87-1.34 4"></path><path d="M3 3l2.43 2.43m14.56 14.56l-3.57-3.57m0 0c-.05.03-.09.07-.13.1 -.78.64-1.32 1.57-1.32 2.58v0c0 1.58-1.29 2.87-2.88 2.87h-.24c-1.6 0-2.89-1.3-2.89-2.89v0c0-1.01-.53-1.93-1.3-2.58 -1.49-1.24-3.71-3.6-3.71-6.55 0-1.7.52-3.28 1.43-4.57m11 11L5.37 5.37m3.56 12.56s1 .5 3 .5 3-.5 3-.5"></path></g></svg>
                <div className='text-lg font-bold'>
                    No results found
                </div>
            </div>
            </>
        )
    }

    if (searchInput && searchResult && (searchResult).length !== 0 && !isFetchingSearchResult) {
        return (
            <>
                <Header pageTitle={`results for "${search}"`}/>
                <Main>
                    <MasonryLayout data={searchResult} lists={lists} key={searchInput} />       
                </Main>

            </>
        )
    }
}

export default Search