import React, { createContext, useContext } from 'react'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useAddBoomark } from '../../hooks/useBookmarkData'
import Header from './Header'
import Sidebar from './Sidebar'
import toast, { Toaster } from 'react-hot-toast';
import useIsMobile from '../Hooks/useIsMobile'


export const SidebarContext = createContext();


const Layout = () => {
  
    const [search, setSearch] = useState('')
  
    const [URL, setURL] = useState('')
  
    const {mutate: addBookmark} = useAddBoomark()

    const isMobile = useIsMobile();

    const [showSidebar, setShowSidebar] = useState(isMobile ? false : true);
  
  
  
    const handleSubmit = async (event) => {
      event.preventDefault()
      addBookmark(URL)
    }  
  
    return (
      <SidebarContext.Provider value={{showSidebar, setShowSidebar}}>  
        <div className='flex flex-row w-full'>
          <Toaster />
          <Sidebar setSearch={setSearch} URL={URL} setURL={setURL} search={search} />
          <div className='flex flex-col w-full overscroll-none'>
            <Outlet  />
          </div>
        </div>
      </SidebarContext.Provider>
    )
  }

export default Layout