import { useState } from "react"
import { toast, Toaster } from "react-hot-toast"
import Header from "../../components/layout/Header"


const TestPage = ({data, lists, key}) => {

    const [source, setSource] = useState('')
    const [course, setCource] = useState('')
    const [component, setComponent] = useState('')
    const [activity, setActivity] = useState('')
    const [item, setItem] = useState('')

    

    const postmanExport = {
        "variables": [],
        "info": {
            "name": "Sample Postman Collection",
            "_postman_id": "35567af6-6b92-26c2-561a-21fe8aeeb1ea",
            "description": "A sample collection to demonstrate collections as a set of related requests",
            "schema": "https://schema.getpostman.com/json/collection/v2.0.0/collection.json"
        },
        "item": [
            {
                "name": "Newman: GET request",
                "event": [
                    {
                        "listen": "test",
                        "script": {
                            "type": "text/javascript",
                            "exec": [
                                "pm.test('expect response be 200', function () {",
                                "    pm.response.to.be.ok",
                                "})",
                                "pm.test('expect response json contain args', function () {",
                                "    pm.expect(pm.response.json().args).to.have.property('source')",
                                "      .and.equal('newman-sample-github-collection')",
                                "})"
                            ]
                        }
                    }
                ],
                "request": {
                    "url": {
                        "raw": "https://postman-echo.com/get?source=newman-sample-github-collection",
                        "protocol": "https",
                        "host": [
                            "postman-echo",
                            "com"
                        ],
                        "path": [
                            "get"
                        ],
                        "query": [
                            {
                                "key": "source",
                                "value": "newman-sample-github-collection",
                                "equals": true,
                                "description": ""
                            }
                        ],
                        "variable": []
                    },
                    "method": "GET",
                    "header": [],
                    "body": {},
                    "description": ""
                },
                "response": []
            },
            {
                "name": "Newman: POST request",
                "request": {
                    "url": "https://postman-echo.com/post",
                    "method": "POST",
                    "header": [
                        {
                            "key": "Content-Type",
                            "value": "text/plain"
                        }
                    ],
                    "body": {
                        "mode": "raw",
                        "raw": "Duis posuere augue vel cursus pharetra. In luctus a ex nec pretium..."
                    },
                    "description": ""
                },
                "response": []
            },
            {
                "name": "Newman: POST request with JSON body",
                "request": {
                    "url": "https://postman-echo.com/post",
                    "method": "POST",
                    "header": [
                        {
                            "key": "Content-Type",
                            "value": "application/json"
                        }
                    ],
                    "body": {
                        "mode": "raw",
                        "raw": "{\"text\":\"Duis posuere augue vel cursus pharetra. In luctus a ex nec pretium...\"}"
                    },
                    "description": ""
                },
                "response": []
            }
        ]
    }

    const url = `https://moodlecore.localhost/webservices/rest/server.php?source=${source}&courseid=${course}&component=${component}&activityid=${activity}&itemnumber=${item}&wstoken=TOKEN&outputformat=json`
    
    const response = {
        id: 'string',
        name: 'string',
        idnumber: 'string',
        description: 'string',
        descriptionformat: 'int'

    }

    const arrayNew = 
    [
        { 
          classname: 'core_reportbuilder_columns_sort_toggle',
          description: 'Toggle sorting of column within report'
        },
        {
          classname: 'core_reportbuilder_conditions_add',
          description: 'Add condition to report'
        },
        {
          classname: 'core_reportbuilder_conditions_delete',
          description: 'Delete condition from report'
        },
        {
          classname: 'core_reportbuilder_conditions_reorder',
          description: 'Re-order condition within report',
        },
        {
          classname: 'core_reportbuilder_conditions_reset',
          description: 'Reset conditions for given report'
        },
        {
          classname: 'core_reportbuilder_filters_add',
          description: 'Add filter to report'
        },
        {
          classname: 'core_reportbuilder_filters_delete',
          description: 'Delete filter from report'
        },
        {
          classname: 'core_reportbuilder_filters_reorder',
          description: 'Re-order filter within report'
        },
        {
          classname: 'core_reportbuilder_audiences_delete',
          description: 'Delete audience from report'
        },
        {
          classname: 'core_reportbuilder_schedules_delete',
          description: 'Delete schedule from report'
        },
        {
          classname: 'core_reportbuilder_schedules_send',
          description: 'Send report schedule'
        },
        {
          classname: 'core_reportbuilder_schedules_toggle',
          description: 'Toggle state of report schedule'
        },
        {
          classname: 'core_admin_set_plugin_state',
          description: 'Set the state of a plugin'
        },
        {
          classname: 'core_admin_set_plugin_order',
          description: 'Set the order of a plugin'
        },
        {
          classname: 'core_admin_set_block_protection',
          description: 'Set the protection state for a block plugin'
        }
      ]
      

    const array = ['core_admin_set_block_protection', 'core_calendar_delete_subscription', 'core_competency_count_competencies_in_template', 'core_competency_remove_competency_from_plan']

    const fdsf = `className="grid h-screen place-items-center font-mono"`

    const [chosen, setChosen] = useState('')

    return (
        <div className="flex flex-col">
            <Toaster />
            <div className="w-full h-full bg-white border-b flex flex-row gap-4 items-center px-4">
                <div className="font-bold text-xl">
                    DHJZ
                </div>
                <ol className="flex flex-row gap-4">
                    <li className="py-[1rem] ">Home</li>
                    <li className="py-[1rem]">Dashboard</li>
                    <li className="py-[1rem]">My Courses</li>
                    <li className="py-[1rem] border-b-2 border-b-[#106cbf]">Site administration</li>

                </ol>
            </div>
            <div className="flex flex-col p-16">
                <div className="flex flex-col gap-4">
                    <div>
                        <span className="text-[#5898d2]">Web Services</span> / API Testing
                    </div>
                    <div className="font-bold text-2xl">
                        School Name
                    </div>
                </div>
                <div>
                    <div className="border-b">
                        <ol className="flex flex-row">
                            <li className="text-[#5898d2] px-[0.5rem] py-[1rem]">General</li>
                            <li className="text-[#5898d2] px-[0.5rem] py-[1rem] border-b border-b-black">Server</li>
                        </ol>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="border-r w-[25rem]">
                        <div className="py-6 flex flex-col gap-4">
                            <div className="font-bold text-2xl">
                            API Testing
                            </div>
                            <div>
                                Search method to test:
                            </div>
                            <div className="flex flex-col gap-4">
                                <div>
                                    <input value={item} onChange={(event) => {
                                        setItem(event.target.value)}
                                    } type="text" className="border rounded-md px-2 py-1"/>
                                </div>
                                <div>
                                    {!item ?
                                    arrayNew.map((item, index) => {
                                        return <div onClick={() => setChosen(item)}>{item.classname}</div>
                                        
                                    })
                                    :
                                    arrayNew.filter((thing, index) => {
                                        
                                        return thing.classname.includes(item);
                                    }).map((item, index) => <div onClick={() => setChosen(item)}>{item.classname}</div>)
                                    }
                                </div>

                                
                            </div>
                        </div>
                    </div>
                    {chosen &&
                        <div className="p-6">
                            <div className="flex flex-col gap-2">
                                <div className="text-[#5898d2]">{chosen.classname}</div>
                                <div className="text-2xl font-bold">
                                    {chosen.description}
                                </div>
                            </div>
                            <div className="py-4">
                    
                            <div className="flex flex-row gap-10 w-full">
                                <div> 
                                    <div className="flex flex-col gap-2">
                                        <div className="font-bold text-xl">
                                            Request
                                        </div>
                                        <div>
                                            Query
                                        </div>
                                        <div>
                                            <ul className="flex flex-col gap-2">
                                                <li className="flex flex-col">
                                                    <div className="flex flex-row gap-2">
                                                        source 
                                                        <span className="font-thin font-sans"> int</span>
                                                    </div>
                                                    <input value={source} onChange={(event) => setSource(event.target.value)} type="text" className="border rounded-md px-2 py-1"/>
                                                </li>
                                                <li className="flex flex-col">
                                                    <div className="flex flex-row gap-2">
                                                        courseid 
                                                        <span className="font-thin font-sans"> int</span>
                                                    </div>
                                                    <input value={course} onChange={(event) => setCource(event.target.value)} type="text" className="border rounded-md px-2 py-1"/>
                                                </li>
                                                <li className="flex flex-col">
                                                    <div className="flex flex-row gap-2">
                                                        component 
                                                        <span className="font-thin font-sans"> int</span>
                                                    </div>
                                                    <input value={component} onChange={(event) => setComponent(event.target.value)} type="text" className="border rounded-md px-2 py-1"/>
                                                </li>
                                                <li className="flex flex-col">
                                                    <div className="flex flex-row gap-2">
                                                        activityid 
                                                        <span className="font-thin font-sans"> int</span>
                                                    </div>
                                                    <input value={activity} onChange={(event) => setActivity(event.target.value)} type="text" className="border rounded-md px-2 py-1"/>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-4 flex-col w-full">
                                    <div className="flex gap-4 flex-col w-full">
                                        <div className="flex flex-row items-center gap-2">
                                            <div className="text-lg font-bold">
                                                Request Sample
                                            </div>
                                            <div className="flex gap-2">
                                                <button className="border rounded px-2 py-1 hover:bg-gray-50">Bash</button>
                                                <button className="border rounded px-2 py-1 hover:bg-gray-50">curl</button>
                                                <button className="border rounded px-2 py-1 hover:bg-gray-50">Postman</button>
                                                <button className="border rounded px-2 py-1 hover:bg-gray-50">SwaggerDocs</button>
                                            </div>
                                        </div>
                                        <div className="bg-gray-100 rounded w-full p-4 flex flex-col">
                                            <div className="w-full flex flex-col gap-2 whitespace-pre-wrap">
                                                <div className="flex justify-end ">
                                                    <button className="border p-1 rounded bg-white flex flex-row gap-2 items-center " onClick={() => {

                                                        navigator.clipboard.writeText(url)

                                                        toast.success("Request URL copied", {
                                                            position: 'bottom-center'
                                                        }
                                                    )}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"/><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/></svg>
                                                    Copy</button>
                                                </div>
                                                {url}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <div className="text-lg font-bold">
                                            Response Sample
                                        </div>
                                        <div className="bg-gray-100 rounded w-[40rem] p-4">
                                            <pre>
                                                {JSON.stringify(response, null, 2)}
                                            </pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
                        </div>
                    }
                </div>
                {/* <div className="py-4">
                    <div className="text-4xl mb-10">
                                Prototype 1
                    </div>
                    <div className="flex flex-row gap-10">
                        <div>
                            
                            <div className="text-2xl font-bold mb-4">
                                Update Grades
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="font-bold text-xl">
                                    Request
                                </div>
                                <div>
                                    Query
                                </div>
                                <div>
                                    <ul className="flex flex-col gap-2">
                                        <li className="flex flex-col">
                                            <div className="flex flex-row gap-2">
                                                source 
                                                <span className="font-thin font-sans"> int</span>
                                            </div>
                                            <input value={source} onChange={(event) => setSource(event.target.value)} type="text" className="border rounded-md px-2 py-1"/>
                                        </li>
                                        <li className="flex flex-col">
                                            <div className="flex flex-row gap-2">
                                                courseid 
                                                <span className="font-thin font-sans"> int</span>
                                            </div>
                                            <input value={course} onChange={(event) => setCource(event.target.value)} type="text" className="border rounded-md px-2 py-1"/>
                                        </li>
                                        <li className="flex flex-col">
                                            <div className="flex flex-row gap-2">
                                                component 
                                                <span className="font-thin font-sans"> int</span>
                                            </div>
                                            <input value={component} onChange={(event) => setComponent(event.target.value)} type="text" className="border rounded-md px-2 py-1"/>
                                        </li>
                                        <li className="flex flex-col">
                                            <div className="flex flex-row gap-2">
                                                activityid 
                                                <span className="font-thin font-sans"> int</span>
                                            </div>
                                            <input value={activity} onChange={(event) => setActivity(event.target.value)} type="text" className="border rounded-md px-2 py-1"/>
                                        </li>
                                        <li className="flex flex-col">
                                            <div className="flex flex-row gap-2">
                                                itemnumber 
                                                <span className="font-thin font-sans"> int</span>
                                            </div>
                                            <input value={item} onChange={(event) => setItem(event.target.value)} type="text" className="border rounded-md px-2 py-1"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            
                        </div>
                        <div className="flex gap-4 flex-col">
                            <div className="flex gap-4 flex-col">
                                <div className="flex flex-row items-center gap-2">
                                    <div className="text-lg font-bold">
                                        Request Sample
                                    </div>
                                    <div className="flex gap-2">
                                        <button className="border rounded px-2 py-1 hover:bg-gray-50">Bash</button>
                                        <button className="border rounded px-2 py-1 hover:bg-gray-50">curl</button>
                                        <button className="border rounded px-2 py-1 hover:bg-gray-50">Postman</button>
                                        <button className="border rounded px-2 py-1 hover:bg-gray-50">SwaggerDocs</button>
                                    </div>
                                </div>
                                <div className="bg-gray-100 rounded w-[40rem] p-4 flex flex-col">
                                    <div className="w-full flex flex-col gap-2 whitespace-pre-wrap">
                                        <div className="flex justify-end ">
                                            <button className="border p-1 rounded bg-white flex flex-row gap-2 items-center " onClick={() => {

                                                navigator.clipboard.writeText(url)

                                                toast.success("Request URL copied", {
                                                    position: 'bottom-center'
                                                }
                                            )}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"/><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/></svg>
                                            Copy</button>
                                        </div>
                                        {url}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="text-lg font-bold">
                                    Response Sample
                                </div>
                                <div className="bg-gray-100 rounded w-[40rem] p-4">
                                    <pre>
                                        {JSON.stringify(response, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="text-4xl mb-10">
                                    Prototype 2 - Selection Menu
                    </div>
                    <div>
                        <input value={item} onChange={(event) => {
                            setItem(event.target.value)}
                        } type="text" className="border rounded-md px-2 py-1"/>
                        {!item ?
                        array.map((item, index) => {
                            return <div>{item}</div>
                            
                        })
                        :

                        array.filter((thing, index) => {
                            return thing.includes(item);
                        }).map((item, index) => <div>{item}</div>)
                    }

                    </div>
                </div> */}
            </div>
        {/* <pre>
            {JSON.stringify(postmanExport, null, 2)}
        </pre> */}
        </div>
    )
}

export default TestPage