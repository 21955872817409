import { useQuery } from "@tanstack/react-query";
import axios from "../../api/axios";
import MasonryLayout from "../../components/MasonryLayout";


export const Home = ({ lists }) => {

 

    const {isLoading, isError, error, data, isFetching: isFetchingAllBookmarks} = useQuery(['home'], () =>
        axios.get('/bookmarks/home')
                    .then(res => {
                        console.log(res.data)
                        return res.data
                    }))

    const {data: random} = useQuery(['random'], () =>
        axios.get('/bookmarks/random')
                .then(res => {
                    console.log(res.data)
                    return res.data
                }))
   
    if (data && random) {
        return (
            <div className='flex flex-col gap-10 mt-[4rem]'>
                <div className='pb-8'>
                    <h2 className='font-bold text-2xl pb-2'>Your latest saves</h2>
                    <div className='enableBlur w-full faded h-[30rem] overflow-hidden'>
                        <MasonryLayout data={data} lists={lists} />                 
                    </div> 
                </div>
                <div>
                    <h2 className='font-bold text-2xl pb-2'>Memories</h2>
                    <div className='enableBlur w-full faded h-[30rem] overflow-hidden'>
                        <MasonryLayout data={random} lists={lists} />                 
          
                    </div> 
                </div>
                
            </div>
        )
    }
    
}