import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../api/axios"
import toast, { Toaster } from 'react-hot-toast';



export const useBookmarkData = (param) => {

    // console.log({param})
    // console.log(param)
    // console.log({fuck: param, shit: `/bookmarks${param && '/' + param}`})
    

    return useQuery(['bookmarks', param], () => 
    axios
        .get(`/bookmarks${param ? ('/' + param) : ''}`)
        .then(res => {
            console.log(res.data)
            return res.data
        })
    )
}

export const useBookmarkTags = (id) => {

    return useQuery(['tags', id], () => 
        axios
            .get(`/tags`, {
                params: {
                    bookmark_id: id
                }
            })
            .then(res => {
                console.log(res.data)
                return res.data
            }))
}




export const useBookmarkIndividual = (id) => {

    return useQuery(['bookmark', id], () => 
        axios
        .get(`/bookmarks/${id}`)
        .then(res => {
            console.log(res.data)
            return res.data
        })    
    )
}

export const useBookmarkSearch = (search) => {

    return useQuery({
        queryKey: ['search-results', search],
        keepPreviousData: true,
        queryFn: () => 
        axios
            .post('/search', {
                search: search
            }, {
                withCredentials: true
            })
            .then(res => {
                console.log(res.data)
                return res.data
            })
        }
    )
}

export const useArticleData = () => {
    return useQuery(['bookmarks-article'], () => 
    axios
        .get('/bookmarks/article')
        .then(res => {
            // console.log(res.data)
            return res.data
        })
    )
}


export const useAddFile = (param) => {
    const queryClient = useQueryClient()

    if (!param) {
        param = ""
    }

    const addFile = (acceptedFiles) => {
        const formData = new FormData()
        formData.append("selectedFile", acceptedFiles[0]);

        return axios.post('/media/file', formData, {
            headers: {
                'Content-Type': "multipart/form-data"
            }
        }).then(data => data)

    }

    return useMutation(addFile, {
        onSuccess: data => {
            const current = queryClient.getQueryData(['library'])
            queryClient.setQueryData(['library'], [data.data, ...current])
            console.log(data)
        },
        onError: data => {
            console.log(data)
            console.log("SHIIIIIIIIIT")
        }
    })

}


export const useAddNote = (param) => {
    const queryClient = useQueryClient()


    if (!param) {
        param = ""
    }

    const addNote = (note) => {
        return axios
            .post('/bookmarks/note', {
                note: note
            })
            .then(data => data)
    }

    return useMutation(addNote, {
        onMutate: data => {
            const current = queryClient.getQueryData(['bookmarks', param])
            console.log(data)
            queryClient.setQueryData(['bookmarks', param], [{type: "note", type_data: {note: data}}, ...current])

        },
        onSuccess: data => {
            const current = queryClient.getQueryData(['bookmarks', param])
            current.shift()
            queryClient.setQueryData(['bookmarks', param], [data.data, ...current])
            console.log(data)
        },
        onError: data => {
            console.log(data)
            console.log("SHIIIIIIIIIT")
        }
    })
}

export const useAddTag = (tagName, bookmark_id) => {
    const queryClient = useQueryClient()

    const addTag = async () => {
        try {
            // Send a request to the Express backend to create the new tag
            const response = await axios.post('/tags', {
                tag: tagName,
                bookmark_id: bookmark_id
            });

            console.log(response.data)
            return response.data
        } catch (error) {
            // Handle any errors
            console.log(error.response.data);
        }
    }

    return useMutation(addTag, {
        onMutate: data => {
            const current = queryClient.getQueryData(['tags', bookmark_id])

            const containsTagAlready = current.find(obj => Object.values(obj).includes(data));
            if (containsTagAlready) {
                return
            } else {
                console.log(current)
            queryClient.setQueryData(['tags', bookmark_id], [...current, {tag: data}])
            const current1 = queryClient.getQueryData(['tags', bookmark_id])
            console.log(current1)
            }

            
        }   
    })
}

export const useDeleteBookmark = (param) => {
    const queryClient = useQueryClient()


    const removeBookmark = (id) => {
        console.log("Deleting")
        return axios
            .delete(`/bookmarks/${id}`, {
            })
            .then((res) => res.data)
    }

    return useMutation(removeBookmark, {
        onMutate: data => {
            console.log({data})
            const current = queryClient.getQueryData(['library'])
            const index = current.findIndex(bookmark => bookmark.bookmark_id === data);
            console.log(index)
            current.splice(index, 1)
            queryClient.setQueryData(['library'], [...current, {type: 'pending'}])
        },
        onSuccess: data => {
            console.log("yay")
            toast.success("Bookmark deleted", {
                position: "top-right"
            })
        },
        onError: data => {
            console.log(data)
            console.log("SHIIIIIIIIIT")
        }
    })
}

export const useAddToList = () => {
    const queryClient = useQueryClient()

    const addToList = ({listId, bookmarkId}) => {

        return axios.post('/lists/add', {
            listId: listId,
            bookmarkId: bookmarkId
        }).then(data => data)
    }

    return useMutation(addToList, {
        onSuccess: data => {
            toast.success("Bookmark added to list", {
                position: 'top-right'
            })
        }
    })
}


export const useAddBoomark = (param) => {
    const queryClient = useQueryClient()

    const addBookmark = (URL) => {
        return axios
            .post('/bookmarks', {
                url: URL
            })
            .then(data => data)
    }

    return useMutation(addBookmark, {
        onMutate: data => {
            toast.loading("Saving bookmark...", {
                id: 'add',
                position: 'top-right'
            })
            // const current = queryClient.getQueryData(['library'])
            // queryClient.setQueryData(['library'], [{type: "new", url: data}, ...current])
        },
        onSuccess: data => {

            if (data?.data?.exists) {
                toast.error("Bookmark already exists", {
                    id: 'add',
                    position: 'top-right'
                })
                return
            } else {
                toast.success("Added bookmark", {
                    id: 'add',
                    position: 'top-right'
                })
                const current = queryClient.getQueryData(['library'])
                // current.shift()
                queryClient.setQueryData(['library'], [data.data, ...current])
                console.log(data)

            }

        },
        onError: data => {
            toast.error("Bookmark unable to be added", {
                id: 'add',
                position: 'top-right'
            })
            // console.log("FUUUUfdsjhfdskjlhsjkghfdjkghdsflkjghdsjklfghjkldfshglkjfdhgkjdsfhgfUUUUUUUUUUCL")
            // const current = queryClient.getQueryData(['library'])
            // current.shift()
            // queryClient.setQueryData(['library'], [...current, {type: 'pending'}])

        }
    })
}

export const useReorderList = () => {
    const queryClient = useQueryClient()

    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        console.log(result)
        return result;
      };

    const updateList = ({sourceIndex, destinationIndex}) => {

        return axios
            .get('/lists/all')
            .then(res => {
                console.log(res.data)
                return res.data
        })
    }

    return useMutation(updateList, {
        onMutate: data => {

            const {sourceIndex, destinationIndex} = data;

            console.log(data)
            const currentListsOrder = queryClient.getQueryData(['lists'])


            const newArray = reorder(
                currentListsOrder,
                sourceIndex,
                destinationIndex
            );

            queryClient.setQueryData(['lists'], [...newArray])
        },
        onSuccess: data => {
            console.log("yay")
            toast.success("Bookmark deleted", {
                position: "top-right"
            })
        },
        onError: data => {
            console.log(data)
            console.log("SHIIIIIIIIIT")
        }
    })

}

export const useGetListofLists = () => {
    const queryClient = useQueryClient()

    
}