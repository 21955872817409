import { Masonry } from 'masonic'
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { BookmarkCard } from './BookmarkCard/BookmarkCard'
import useIsMobile from './Hooks/useIsMobile'

const MasonryLayout = ({data, lists, key}) => {

    const CardWithLists = useCallback(
        props => <BookmarkCard 
            {...props}
            lists={lists}
        />,
        []
    )

    const isMobile = useIsMobile();

    if (key) {
        return (
            <div className='enableBlur w-full'>
                <Masonry
                    key={key}
                    items={data}
                    columnGutter={isMobile ? 15: 35}
                    columnWidth={isMobile ? 120 : 200}
                    overscanBy={3}
                    render={CardWithLists}
                />                    
            </div>
        )
    }


    return (
        <div className='enableBlur w-full cardPage'>
            <Masonry
                items={data}
                columnGutter={isMobile ? 15: 35}
                columnWidth={isMobile ? 120 : 200}
                overscanBy={3}
                render={CardWithLists}
            />                    
        </div>
    )
}

export default MasonryLayout