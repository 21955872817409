import * as Dialog from '@radix-ui/react-dialog';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useContext } from 'react';
import axios from '../api/axios';
import ModalContext from '../context/ModalContext';
import { useAddTag, useBookmarkTags, useDeleteBookmark } from '../hooks/useBookmarkData';
import { useImage } from '../hooks/useImage';


export const ListModal = ({ children }) => {

    const queryClient = useQueryClient()

    const [listName, setListName] = useState('')

    const handleSubmit = () => {
        axios.post(
            '/lists/new',
            {
                listName
            }
        ).then((res) => {
            console.log(res.data)
            queryClient.invalidateQueries({queryKey: ['lists']})
        }
        )
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>
                {children}
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className='bg-black bg-opacity-[0.2] transition-shadow fixed inset-0 z-40 DialogOverlay'>
                    <Dialog.Content className='DialogContent fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4'>
                        <div className='flex flex-col gap-6 bg-white px-8 py-5 pt-6 rounded-lg w-[25rem]'>
                            
                            <div className=''>
                                <div className='text-xl font-bold items-center flex flex-row'>
                                    <p className='w-2/3'>
                                        Create new list
                                    </p>
                                    <div className='flex justify-end w-1/3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                </div>
                                <div className='text-gray-400 text-sm'>
                                    Add a new list to organise your bookmarks
                                </div>
                            </div>
                            <input placeholder='list name' className='w-full border rounded-lg p-2' type="text" value={listName} onChange={(event) => {
                                setListName(event.target.value)
                                console.log(listName)
                            } } />
                            <div className='flex flex-row justify-end gap-4'>
                                <button className='border rounded-lg p-2'>Cancel</button>
                                <button className='border rounded-lg p-2' onClick={handleSubmit}>Create</button>
                            </div>
                        </div>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}
