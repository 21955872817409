import * as Dialog from '@radix-ui/react-dialog';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useContext } from 'react';
import ModalContext from '../context/ModalContext';
import { useAddTag, useBookmarkTags, useDeleteBookmark } from '../hooks/useBookmarkData';
import { useImage } from '../hooks/useImage';


export const BookmarkModal = ({ param }) => {


    const {modal, selected} = useContext(ModalContext)
    
    const [showModal, setShowModal] = modal;
    const [selectedBookmark, setSelectedBookmark] = selected


    const type = selectedBookmark.type
    const tags = selectedBookmark.tags

    

    const { title, site_name } = selectedBookmark


    const {mutate: removeBookmark} = useDeleteBookmark()
    const handleDelete = async (event) => {
        event.preventDefault()
        setShowModal(false)
        removeBookmark(selectedBookmark.bookmark_id)
    }   

    const [tagName, setTagName] = useState('');


    const {mutate: addTag} = useAddTag(tagName, selectedBookmark.bookmark_id)
    const handleAddTag = async (e) => {
        e.preventDefault();
        addTag(tagName, selectedBookmark.bookmark_id)
        setTagName('')
    }


    const fetchTags = useBookmarkTags(selectedBookmark.bookmark_id)

    
    const { data, error, isFetching, refetch } = useQuery(
        ['tags', selectedBookmark.bookmark_id],
        fetchTags
    );



    return (
        <Dialog.Root open={showModal}>
            <Dialog.Portal>
                <Dialog.Overlay className='bg-black bg-opacity-[0.2] transition-shadow fixed inset-0 z-40 DialogOverlay'>
                    <Dialog.Content onEscapeKeyDown={() => setShowModal(false)} onPointerDownOutside={() => setShowModal(false)} className='DialogContent'>
                        <div className='transition-opacity duration-1000 delay-1000 ease-in bg-opacity-100 w-[70%] max-w-[1500px] h-[70%] rounded-xl  fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-row overflow-hidden'>
                            <div className='w-[70%] h-full flex justify-center items-center flex-col'>
                                <div className='flex w-full h-full'>
                                    <div className='w-full h-full bg-white fucker flex justify-start items-start rounded border overflow-y-scroll'>
                                        {type === 'website' && site_name !== 'SoundCloud' && site_name !== 'Spotify' && <DialogWebsite bookmark={selectedBookmark} />}
                                        {type === 'video' && <iframe className='w-full h-full' src={selectedBookmark.type_data.embed_url}title='video'></iframe>}
                                        {type === 'image' && <DialogImage bookmark={selectedBookmark} />}
                                        {type === 'twitter' && <DialogTwitter bookmark={selectedBookmark} />}
                                        {type === 'TikTok' && <DialogTiktok bookmark={selectedBookmark} />}
                                        {type === 'article' && 
                                            <div className='flex justify-center'>
                                                <div 
                                                    className='whitespace-normal p-10 imaage text-lg'
                                                    dangerouslySetInnerHTML={{ __html: selectedBookmark.type_data.mainContent}}>
                                                </div>
                                            </div>}
                                        {type === 'Reddit' && <DialogReddit bookmark={selectedBookmark} />}
                                        {site_name === 'Spotify' && <iframe className='w-full h-full' allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" src="https://open.spotify.com/embed/track/5ADT1DgRH94JdXcAy1IxMj?utm_source=generator" ></iframe>}
                                        {site_name === 'SoundCloud' && <iframe className='w-full h-full' src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1362450349&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true' ></iframe>}
                                    </div>
                                </div>
                            </div>
                            <div className='w-[30%] p-4 flex flex-col bg-white '>
                                        <p className='text-xl font-bold w-full custom pb-1'>
                                            {(type === 'website' || type ==='video' || type === 'Reddit' || type === 'article') && selectedBookmark.type_data.title}
                                            {(type) === 'twitter' && selectedBookmark.type_data.authorName}
                                        </p>
                                        <hr className='h-[0px] divide-solid py-1' />
                                        <p className='text-sm py-1'>
                                            <span className='flex flex-row gap-1'>
                                                Saved 2 days ago 
                                            </span>
                                            
                                        </p>
                                        <div>
                                            <p className='uppercase font-bold py-2 text-md tracking-widest'>
                                                Tags
                                            </p>
                                            <form onSubmit={handleAddTag}>
                                                <input
                                                    type="text"
                                                    placeholder="Enter tag..."
                                                    value={tagName}
                                                    onChange={e => setTagName(e.target.value)}
                                                    className='border rounded-full px-2 py-1 w-full'
                                                    
                                                />
                                            </form>
                                            <div className='flex gap-2 mt-1 flex-wrap'>
                                                <ul className=' w-full flex gap-2 py-2 flex-wrap'>
                                                    {data && data.map(tag => (
                                                        <li className='p-1 rounded-full bg-violet-200 text-violet-600 px-2' key={tag.tag_id}>
                                                            {tag.tag}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='w-full flex flex-row justify-end mt-3'>
                                            {/* <button className='p-2 bg-orange-300 rounded-lg text-white' onClick={() => {
                                                const selection = window.getSelection();
                                                console.log(selection.toString())
                                            }}>Selection</button> */}
                                            <button className='p-2 bg-red-300 rounded-lg text-white' onClick={handleDelete}>
                                                Delete
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

const DialogWebsite = ({ bookmark }) => {
    const {isLoading, isError, error, data, isFetching} = useImage(bookmark?.type_data?.fullpage_key)
    return (
        <div className='w-full'>
            <div className='p-2 bg-white border-b'>
                <a href={bookmark.url} className='text-sm text-gray-500'>{bookmark.url}</a>
            </div>
            <div className='overflow-y-scroll'>
                <img src={data} alt=""  />
            </div>
            
        </div>
    )
}

const DialogImage = ({ bookmark }) => {

    const {fileKey, originalName} = bookmark.type_data;

    const {isLoading, isError, error, data, isFetching} = useImage(fileKey)
    return (
        <div className='flex justify-center items-center h-full'>
            <img src={data} alt="" className='h-3/4 rounded-lg fuck' />
        </div>
    )
}

const DialogTiktok = ({ bookmark }) => {

    const {videoURL} = bookmark.type_data

    return (
        <div className='flex justify-center items-center h-full w-full bg-white'>
            <video muted controls src={videoURL} className='h-[95%] rounded-lg fuck' ></video>
        </div>
    )
}

const DialogReddit = ( { bookmark } ) => {

    const typeData = bookmark.type_data
    const postType = typeData.post_type

    const {title, selftext, subreddit, selftext_html, type_url, media, is_video, url_overridden_by_dest, permalink, is_gallery, mediaArray} = typeData



    return (
        <div className='w-full
                        flex justify-center h-full items-center'>
            <div className='rounded-lg bg-white fuck max-w-[70%] max-h-[70%] overflow-y-auto'>
                <div className='p-4 h-full flex flex-col gap-1 '>
                    <div className='flex-auto'>r/{subreddit}</div>
                    <div className='text-xl  flex-auto font-bold'>
                        {title}
                    </div>
                    {postType === 'text' && 
                        <div className='whitespace-pre-line flex-auto'>
                            {typeData.selftext}    
                        </div>}
                    {postType === 'image' &&
                        <div className='flex w-full h-full'>
                            <img src={typeData.imageLink} alt='reddit' className='object-scale-down'/>    
                        </div>}
                    {postType === 'galleryImage' &&
                        <div className='flex w-full h-full '>
                            <img src={typeData.mediaArray[0]} alt='reddit' className='object-scale-down'/>    
                        </div>}
                    <div>

                    </div>
                </div>
               
            </div>
        </div>
    )
}

const DialogTwitter =( { bookmark }) => {

    const typeData = bookmark.type_data

    const { media } = typeData


    return (
        <div className='w-full h-full bg-gradient-to-b from-[#d7eefc] via-white to-white flex justify-center items-center'>
            <div className='rounded-lg fuck max-w-[625px] max-h-[765px] overflow-auto '>
                <div className='bg-white p-8 flex flex-col'>
                    <span className='text-md whitespace-pre-line leading- text-xl'>{typeData.tweetText}</span>
                    <div className='flex flex-row gap-1 rounded mt-4'>
                        {media && media.map((media, index) => {
                            return media.type === 'photo' && <img src={media.url} alt="" className='flex w-full max-h-[405px] rounded object-contain'/>
                        })}
                    </div>
                </div>
                <div className='bg-[#f0f2f5] p-4 px-8'>
                        Tweet by
                </div>
            </div>
            {/* <svg className='mb-2 w-5 fill-[#23a2f0]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg> */}
        </div>
    )
}