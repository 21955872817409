import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from './reportWebVitals';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import supertokens, { SuperTokensWrapper, getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
// import ThirdPartyPasswordless from "supertokens-auth-react/recipe/thirdpartypasswordless";
import Session, { SessionAuth, useSessionContext } from "supertokens-auth-react/recipe/session";
import { getThirdPartyAuthorisationURLWithQueryParamsAndSetState } from "supertokens-web-js/recipe/thirdpartypasswordless";
import getCookieHandler from './cookieHandler';
import getWindowHandler from './windowHandler';
import ThirdPartyEmailPassword, {Github, Google, Facebook, Apple} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import '@fontsource/nunito';

if (typeof window !== "undefined") {
  supertokens.init({
      appInfo: {
          appName: "bookmarks",
          apiDomain: process.env.REACT_APP_API_BASE_URL,
          websiteDomain: process.env.REACT_APP_CLIENT_URL,
          apiBasePath: "/auth",
          websiteBasePath: "/auth"
      },
      cookieHandler: getCookieHandler,
      windowHandler: getWindowHandler,
      recipeList: [
        ThirdPartyEmailPassword.init({
              contactMethod: "EMAIL",
              signInAndUpFeature: {
                providers: [
                    Github.init(),
                    Google.init(),
                    Facebook.init(),
                    Apple.init(),
                ]
              },
              preAPIHook: async (context) => {

                let url = context.url;
                // is the fetch config object that contains the header, body etc..        
                let requestInit = context.requestInit;
                let clientType = window?.env?.clientType || 'webapp'
                if (context.action === 'GET_AUTHORISATION_URL') {
                  let newUrl = context.url + `&clientType=${clientType}`
                  context.url = newUrl            
                  return context
                }

                return {
                  requestInit, url
                }

            },
              onHandleEvent: async (context) => {
                if (context.action === "SESSION_ALREADY_EXISTS") {
  
                } else {
                  if (context.action === "SUCCESS") {
                    
                }}
          }}),
          Session.init()
      ]
  });
}



export async function googleSignInClicked() {
  try {
      const authUrl = await getThirdPartyAuthorisationURLWithQueryParamsAndSetState({
          providerId: "google",

          // This is where Google should redirect the user back after login or error.
          // This URL goes on the Google's dashboard as well.
          authorisationURL: "http://localhost:3000/auth/callback/google",
      });

      /*
      Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
      */

      // we redirect the user to google for auth.
      window.location.assign(authUrl);
  } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          window.alert(err.message);
      } else {
          window.alert(err.message);
      }
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
})
const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <React.StrictMode>

      <QueryClientProvider client={queryClient}>
            <SuperTokensWrapper>
              <App />
            </SuperTokensWrapper>
      </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
