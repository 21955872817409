import {
  Root,
  Trigger,
  Portal,
  Content,
  Item,
  Sub,
  SubTrigger,
  SubContent,
} from "@radix-ui/react-context-menu";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../api/axios";
import { useDeleteBookmark } from "../hooks/useBookmarkData";
import toast, { Toaster } from "react-hot-toast";

// TODO: Add option to remove bookmark from list (if in a list page)

export const useDeleteList = () => {
  const queryClient = useQueryClient();

  const deleteList = (listId, listName) => {
    console.log(listId);
    console.log(`Deleting ${listId}`);
    return axios.delete(`/lists/${listId}`, {}).then((res) => res.data);
  };

  return useMutation(deleteList, {
    onMutate: (data) => {
      console.log({ data });
      const current = queryClient.getQueryData(["lists"]);
      const index = current.findIndex(
        (list) => list.list_id === data
      );
      console.log(`Deleted index: ${index}`);
      current.splice(index, 1);
      queryClient.setQueryData(["lists"], [...current]);
    },
    onSuccess: (data) => {
      console.log("yay");
      toast.success("List deleted", {
        position: "top-right",
      });
    },
    onError: (data) => {
      console.log(data);
      console.log("SHIIIIIIIIIT");
    },
  });
};

export const ListContextMenu = ({ children, listId, listName }) => {
  const item = "flex items-center relative hover:bg-gray-50 p-2 rounded-lg";
  const lists = false;

  const { mutate: deleteList } = useDeleteList();
  const handleDelete = async (event) => {
    event.preventDefault();
    // setShowModal(false);
    deleteList(listId, listName);
  };

  return (
    <Root>
      <Trigger asChild={true}>{children}</Trigger>
      <Portal>
        <Content className="ContextMenuContent bg-white p-2 rounded-lg border context-menu-shadow z-[90] w-56">
          <Item className={`${item} text-red-400`} onClick={handleDelete}>
            Delete list </Item>
        </Content>
      </Portal>
    </Root>
  );
};
