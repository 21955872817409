import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import axios from '../../api/axios'
import { BookmarkCard, BookmarkCardForList } from '../../components/BookmarkCard/BookmarkCard'
import Header from '../../components/layout/Header'
import React, {useEffect, useState} from 'react';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';




const Lists = () => {

    const {data: lists} = useQuery(['lists'], () => 
        axios
            .get('/lists/all')
            .then(res => {
                console.log(res.data)
                return res.data
            })
    )

    if (lists) {
        
        return (
            <>
                <Header pageTitle='lists'/>
                <div className='w-full'>
                    <div className='flex justify-center flex-row flex-wrap gap-[30px] cardPage z-[90] mt-4'>
                        {/* <ListCards listId={lists[0].list_id} /> */}
                        {lists.map((item, index) => {
                            return (
                                <Link to={`/list/${item.list_id}`} state={{listTitle: item.list_name, listId: item.list_id, listColour: item.list_colour}}>
                                    <ListCards listId={item.list_id} key={item.list_id}  />
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }

}

const ListCards = ({ listId }) => {
    
    const {isLoading, data: listItems, isFetching, refetch} = useQuery(['list', listId], () =>
        axios
            .get(`/lists/${listId}`)
            .then(res => res.data)
    )

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }


    if (listItems) {

        const bookmarks = listItems.bookmarks

        return (
            <div className='flex flex-initial flex-col text-center'>
              <div className='relative flex items-center justify-center text-center min-w-[150px] h-[150px]'>
                {bookmarks.map((item, index) => {
                    return (
                        <div 
                        key={item.bookmark_id+listId}
                        className={`top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                         w-full absolute duration-[600s] rotate-[${randomIntFromInterval(-10, 10)}deg]`}>
                            <BookmarkCardForList bookmark={item} />
                        </div>
                    )
                })}
                
                </div>
                <div className='text-base'>
                {listItems.listName}
            </div>
            </div>
          
        )
    }

    // rotate-[15deg]
// rotate-[14deg]
// rotate-[13deg]
// rotate-[12deg]
// rotate-[11deg]
// rotate-[10deg]
// rotate-[9deg]
// rotate-[8deg]
// rotate-[7deg]
// rotate-[6deg]
// rotate-[5deg]
// rotate-[4deg]
// rotate-[3deg]
// rotate-[2deg]
// rotate-[1deg]
// rotate-[0deg]
// rotate-[1deg]
// rotate-[-1deg]
// rotate-[-2deg]
// rotate-[-3deg]
// rotate-[-4deg]
// rotate-[-5deg]
// rotate-[-6deg]
// rotate-[-7deg]
// rotate-[-8deg]
// rotate-[-9deg]
// rotate-[-10deg]
// rotate-[-11deg]
// rotate-[-12deg]
// rotate-[-13deg]
// rotate-[-14deg]
// rotate-[-15deg]


}


function App() {

    const {data: lists} = useQuery(['lists'], () => 
        axios
            .get('/lists/all')
            .then(res => {
                console.log(res.data)
                return res.data
            })
    )

  const [items, setItems] = useState([{list_id: "fuck"}, {list_id: "fuckers"}]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  

  if (items) {
    return (
        <DndContext 
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext 
            items={items}
            strategy={verticalListSortingStrategy}
          >
            {items.map((id, index) => <SortableItem key={index} id={index} />)}
          </SortableContext>
          <button onClick={() => console.log(items)}>Click</button>
        </DndContext>
      );
  }
  
  
  function handleDragEnd(event) {
    const {active, over} = event;
    console.log({active, over})
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        console.log(oldIndex)
        const newIndex = items.indexOf(over.id);
        
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
}


export function SortableItem(props) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: props.id});
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    
    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        Hey {props.id}
      </div>
    );
  }

export default Lists