import * as Dialog from '@radix-ui/react-dialog';
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import axios from '../../api/axios';
import Header from '../../components/layout/Header';



export const Settings = () => {


    const navigate = useNavigate();

    const handleLogOut = async () => {
        await signOut()
        navigate("/")
    }

    const { data, error, isFetching, refetch, isLoading } = useQuery(['integrations'], () => 
    axios
        .get(`/integrations/status`)
        .then(res => {
            console.log(res.data)
            
            return res.data
        })
    )

    let reddit, twitter, youtube, hackernews;

    const configureButton = <button className='border border-black rounded-lg p-1'>Configure</button>

    if (isLoading) {
        return (
            <>
                <Header pageTitle='Settings' refetch={refetch} isFetching={isFetching} />
            <button onClick={handleLogOut}>Logout</button>

            </>
        )
    }

    if (data) {
        
        reddit = data.reddit
        twitter = data.twitter
        youtube = data.youtube
        hackernews = data.hackernews

        return (
            <>
                <Header pageTitle='Settings' refetch={refetch} isFetching={isFetching} />
                <div className='w-full p-[35px] flex flex-col h-full justify-center'>
                    <div className='font-bold text-xl'>
                        Integrations
                        
                    </div>
                    <div className='grid grid-cols-2 w-full gap-4'>
                        <div className='w-full rounded-xl border p-4 flex gap-2 flex-col'>
                            <div className='flex justify-between'>
                                <svg viewBox="0 0 20 20" className='w-8'><g><circle fill="#FF4500" cx="10" cy="10" r="10"></circle><path fill="#FFF" d="M16.67,10A1.46,1.46,0,0,0,14.2,9a7.12,7.12,0,0,0-3.85-1.23L11,4.65,13.14,5.1a1,1,0,1,0,.13-0.61L10.82,4a0.31,0.31,0,0,0-.37.24L9.71,7.71a7.14,7.14,0,0,0-3.9,1.23A1.46,1.46,0,1,0,4.2,11.33a2.87,2.87,0,0,0,0,.44c0,2.24,2.61,4.06,5.83,4.06s5.83-1.82,5.83-4.06a2.87,2.87,0,0,0,0-.44A1.46,1.46,0,0,0,16.67,10Zm-10,1a1,1,0,1,1,1,1A1,1,0,0,1,6.67,11Zm5.81,2.75a3.84,3.84,0,0,1-2.47.77,3.84,3.84,0,0,1-2.47-.77,0.27,0.27,0,0,1,.38-0.38A3.27,3.27,0,0,0,10,14a3.28,3.28,0,0,0,2.09-.61A0.27,0.27,0,1,1,12.48,13.79Zm-0.18-1.71a1,1,0,1,1,1-1A1,1,0,0,1,12.29,12.08Z"></path></g></svg>
                                <div className={`rounded-full border ${reddit ? 'border-green-600 text-green-600' : 'border-red-600 text-red-600'} text-xs p-2 px-3`}>
                                    {reddit ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                            <div className='text-lg font-bold'>Reddit</div>
                            <div className='flex justify-end'>
                                <IntegrationsDialog button={configureButton} service='reddit'/>
                            </div>
                        </div>
                        <div className='w-full rounded-xl border p-4 flex gap-2 flex-col'>
                            <div className='flex justify-between'>
                                <svg className='w-7 fill-[#23a2f0]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/>
                                </svg>     
                                <div className={`rounded-full border ${twitter ? 'border-green-600 text-green-600' : 'border-red-600 text-red-600'} text-xs p-2 px-3`}>
                                    {twitter ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                            <div className='text-lg font-bold'>Twitter</div>
                            <div className='flex justify-end'>
                                <IntegrationsDialog button={configureButton} service='twitter'/>
                            </div>
                        </div>
                        <div className='w-full rounded-xl border p-4 flex gap-2 flex-col'>
                            <div className='flex justify-between'>
                                <svg className='w-11' xmlns="http://www.w3.org/2000/svg" viewBox="-35.20005 -41.33325 305.0671 247.9995"><path d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85" fill="red"/><path d="M93.333 117.559l61.333-34.89-61.333-34.894z" fill="#fff"/></svg>                                                   
                                <div className={`rounded-full border ${youtube ? 'border-green-600 text-green-600' : 'border-red-600 text-red-600'} text-xs p-2 px-3`}>
                                    {youtube ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                            <div className='text-lg font-bold'>YouTube</div>
                            <div className='flex justify-end'>
                                <IntegrationsDialog button={configureButton} service='youtube'/>
                            </div>
                        </div>
                        <div className='w-full rounded-xl border p-4 flex gap-2 flex-col'>
                            <div className='flex justify-between'>
                            <svg className='w-8' xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path fill="#F36003" d="M23.25,3.161c0-1.331-1.08-2.411-2.411-2.411H3.161C1.83,0.75,0.75,1.83,0.75,3.161v17.679c0,1.331,1.08,2.411,2.411,2.411h17.679c1.331,0,2.411-1.08,2.411-2.411V3.161z"/><path d="M20.786,24H3.214C1.441,24,0,22.558,0,20.786V3.214C0,1.442,1.441,0,3.214,0h17.572C22.559,0,24,1.442,24,3.214v17.571C24,22.558,22.559,24,20.786,24z M3.214,1.5C2.269,1.5,1.5,2.269,1.5,3.214v17.571c0,0.945,0.769,1.714,1.714,1.714h17.572c0.945,0,1.714-0.769,1.714-1.714V3.214c0-0.945-0.769-1.714-1.714-1.714H3.214z"/><path d="M11.954,13c-0.001,0-0.003,0-0.004,0c-0.253-0.001-0.488-0.13-0.626-0.343l-4.204-6.5C6.896,5.81,6.995,5.345,7.343,5.12C7.69,4.897,8.154,4.994,8.38,5.343l3.581,5.537l3.663-5.543c0.229-0.345,0.693-0.442,1.039-0.212c0.346,0.228,0.441,0.693,0.213,1.039l-4.296,6.5C12.44,12.874,12.206,13,11.954,13z"/><path d="M12,19c-0.414,0-0.75-0.336-0.75-0.75v-6c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v6C12.75,18.664,12.414,19,12,19z"/></svg>
                                <div className={`rounded-full border ${hackernews ? 'border-green-600 text-green-600' : 'border-red-600 text-red-600'} text-xs p-2 px-3`}>
                                    {hackernews ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                            <div className='text-lg font-bold'>Hacker News</div>
                            <div className='flex justify-end'>
                                <IntegrationsDialog button={configureButton} service='hacker news'/>
                            </div>
                        </div>
                    </div>
                </div>
                <button onClick={handleLogOut}>Logout</button>
            </>
        )
    } else {
        return (
            <button onClick={handleLogOut}>Logout</button>
        )
    }
}

const IntegrationsDialog = ({button, service}) => {


    const openRedditAuth = () => {
        const callbackUrl = `${process.env.REACT_APP_API_BASE_URL}/integrations/redirect/reddit`;

        window.open(
            `https://www.reddit.com/api/v1/authorize?client_id=clZ_PJYPkLUKTSCd_kEsYw&response_type=code&state=RANDOM_STRING&redirect_uri=${callbackUrl}&duration=permanent&scope=identity+history+save`, "_self"
        )
    }

   

    return (
        <Dialog.Root >
            <Dialog.Trigger>
                {button}
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className='bg-white bg-opacity-[0.2] transition-shadow fixed inset-0 z-40'>
                    <Dialog.Content>
                        <div className='fucker border bg-white w-[40%] max-w-[1500px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-xl flex flex-row overflow-hidden'>
                            <div className='p-4 flex flex-col gap-4'>
                                <div className='text-xl font-bold'>
                                    <span className='capitalize'>{service}</span> Settings
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <div>After connecting Reddit, Sehn will ingest your saved Reddit posts and add them to your Library.</div>
                                    <div>Sehn will also regularly check for saved posts, so you only need to connect once.</div>
                                </div>
                                <div className='flex justify-end'>
                                    <button className='p-2 border rounded-lg' onClick={() => openRedditAuth()}>Connect</button>
                                </div>
                            </div>
                        </div>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}