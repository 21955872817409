import React, {useState, useEffect} from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import PageLoader from './PageLoader';
import { useAxiosGet } from './Hooks/HttpRequest';
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";
import axios from '../api/axios';
import { getThirdPartyAuthorisationURLWithQueryParamsAndSetState } from "supertokens-web-js/recipe/thirdpartypasswordless";
import { googleSignInClicked } from '..';
import { BookmarkCardForList, DemoBookmarkCard } from './BookmarkCard/BookmarkCard';




function Homepage({ name }) {

    const params = useParams();

    const session = useSessionContext();

    const handleSignOut = async () => {
        await signOut()
    }

    const navigate = useNavigate();


    const [url, setUrl] = useState('')

    const [bookmark, setBookmark] = useState('')

    const getBookmark = (e) => {
        e.preventDefault();

        axios
            .post('/bookmarks/demo', {
                url: url
            })
            .then(data => {
                // console.log(data.data)
                setBookmark(data.data)
            })
    }

    // text-gray-400

    return (
            <div className='flex lg:flex-row h-screen lg:justify-center lg:items-center'>
                <div className='flex flex-col lg:flex-row md:flex-row md:justify-center lg:justify-center  lg:p-4 md:w-3/4 lg:w-3/4 max-w-[1400px]'>
                    <div className='p-6 md:p-0 flex lg:flex-col flex-col items-start lg:py-4 flex-wrap lg:w-1/3'>
                        <h1 className=' flex flex-row items-center text-xl gap-2 lg:text-2xl lg:gap-4'>
                            <svg width="50" height="50" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42.5759 50.536L43.6737 55.2275L11.4106 37.3475L10.2515 32.6915L11.855 33.512L42.5741 50.536H42.5759Z" fill="#9A91D5"></path><path d="M48.417 24.9982L54.4623 50.8373L50.1405 56.3351L44.0952 30.4959L48.417 24.9982Z" fill="#9280C1"></path><path d="M48.417 24.9982L44.0952 30.496L37.3103 28.0302L41.6321 22.5325L48.417 24.9982Z" fill="#B5A2BF"></path><path d="M46.8373 11.4746L48.432 17.7386L44.2355 14.0933L42.6409 7.8302L46.8373 11.4746Z" fill="#B5A2BF"></path><path d="M48.4319 17.7387L23.4337 31.4187L10.2515 32.6916L44.2355 14.0934L48.4319 17.7387Z" fill="#9280C1"></path><path d="M40.2888 40.7609L42.5759 50.536H42.5741L11.855 33.512L10.2515 32.6916L23.4337 31.4187V31.4196L40.2888 40.7609Z" fill="#8478CC"></path><path d="M44.0952 30.4961L50.1405 56.3352L43.6739 55.2276L42.5761 50.5361L40.289 40.7609L37.3103 28.0303L44.0952 30.4961Z" fill="#A191C4"></path><path d="M42.6408 7.8302L44.2355 14.0933L10.2515 32.6915L8.65771 26.4284L42.6408 7.8302Z" fill="#A191C4"></path></svg>
                            Saven
                        </h1>
                        <h2 className='mt-4 text-3xl font-bold'>Organise your bookmarks visually</h2>
                        <p className='mt-4'>Integrate with Twitter, Reddit, and YouTube to save your favourite content automatically</p>      
                        <div className='flex flex-row gap-4 mt-4 flex-wrap w-full'>
                            <button onClick={() => navigate("/auth")} className='p-2 px-3 rounded-xl border hover:bg-gray-100 transistion duration-500'>Login</button>
                            <a href="https://github.com/djarran/commonplace">
                                <button className='p-2 px-3 rounded-xl flex flex-row gap-2 border hover:bg-gray-100 transistion duration-500'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/></svg>
                                    GitHub
                                </button>
                            </a>
                            <button className='p-2 px-3 rounded-xl flex flex-row gap-2 border hover:bg-gray-100 transistion duration-500 grow justify-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><polyline points="7 10 12 15 17 10"/><line x1="12" y1="15" x2="12" y2="3"/></svg>
                                Download
                            </button>
                        </div>
                        <form onSubmit={getBookmark} className='flex-row flex w-full mt-6' >
                            <div className='w-full flex flex-row items-center gap-4 focus:outline-none  bg-white rounded-xl px-4 py-2 search-shadow border'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='text-gray-400' fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"/></svg>
                                <input placeholder='Try a URL' value={url} onChange={(event) => setUrl(event.target.value)} type="text" className="w-full focus:outline-none bg-transparent bg-white text-gray-400" />
                            </div>
                            {/* <button type="submit" className='rounded text-white bg-green-500'>Submit</button> */}
                        </form>
                        {/* <button onClick={() => console.log(url)}>Clikc</button> */}
                        <div className='w-full mt-6 relative'>
                            <div className='absolute'>
                                {bookmark && <DemoBookmarkCard bookmark={bookmark}/>}
                            </div>
                        </div>
                        
                    </div>
                    <img className='md:w-2/3 lg:w-2/3 px-2 lg:mt-0 lg:p-0 items-center' src='/screenshot.png'></img>
                </div>
                
            </div>

            // {/* <div className='flex flex-col gap-2'>
            //     {!session.doesSessionExist ?
            //         <button className='p-1 bg-black text-white rounded' onClick={() => navigate("/auth")}>Sign In</button>
            //         :
            //         <button className='p-1 bg-black text-white rounded w-20' onClick={handleSignOut}>Sign Out</button>}
            //     Your session user id: {session.userId}
            //     <button className='p-1 bg-black text-white rounded' onClick={() => googleSignInClicked()}>Get Info</button>
            //     <button className='p-1 bg-black text-white rounded' onClick={() => axios.get("/bookmark/test")}>Get Request</button>

            // </div>
            // <div>
            //     <button onClick={googleSignInClicked} >Hey</button>
            // </div> */}
    )
}




export default Homepage