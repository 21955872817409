import React, { useContext, useRef, useState } from 'react'
import { createSearchParams, Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from '../../api/axios';
import { useQuery } from '@tanstack/react-query'
import { ListModal } from '../ListModal';
import { useDrop } from '@react-aria/dnd';
import { toast } from 'react-hot-toast';
import { useAddToList } from '../../hooks/useBookmarkData';
import ModalContext from '../../context/ModalContext';
import useIsMobile from '../Hooks/useIsMobile';
import { SidebarContext } from './Layout';
import Tags from '../../pages/Tags/Tags';
import ListOfLists from '../ListOfLists';




const Sidebar = ({ setSearch, handleSubmit, URL, setURL, search }) => {

    let location = useLocation()
    console.log(location.pathname)
    
    const navigate = useNavigate();

    const handleSearch = (event) => {
        if (event.target.value === '') {
            navigate("/library")
            window.history.pushState({}, "bookmarks", `/library`)
        } else {
            
            navigate({
                pathname: '/search',
                search: createSearchParams('query').toString() + event.target.value
            })
            console.log(search)
        }

        setSearch(event.target.value)

        
    }    
  
    const removeSearch = () => {
        setSearch('')
        setShowSidebar(false)
    }

    let {type} = useParams()

    const {data: lists} = useQuery(['lists'], () => 
        axios
            .get('/lists/all')
            .then(res => {
                console.log(res.data)
                return res.data
            })
    )

    const [display, setDisplay] = useState(false);

    const {showSidebar, setShowSidebar} = useContext(SidebarContext);

    const isMobile = useIsMobile();

    const mob = 'fixed h-full z-[999] '

    return (
        
        <div className={` flex flex-row md:block lg:block xl:block ${isMobile ? (showSidebar ? mob + "-translate-x-0" : mob + "-translate-x-full hidden") : ''}`}>
            <div className={`${showSidebar && 'sidebar'} justify-start w-[240px] bg-white text-black fixed h-full flex flex-col gap-4 border-r border-gray-200`}>
                <div className='items-center w-full flex-none px-6 '>
                    <h1 className='text-xl font-bold h-16 flex items-center'>
                        {!window?.env?.clientType && 
                            <span className='flex flex-row w-full gap-3 text-black justify-between'>
                                <div className='flex flex-row gap-3 text-black'>
                                    <svg width="25" height="25" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42.5759 50.536L43.6737 55.2275L11.4106 37.3475L10.2515 32.6915L11.855 33.512L42.5741 50.536H42.5759Z" fill="#9A91D5"></path><path d="M48.417 24.9982L54.4623 50.8373L50.1405 56.3351L44.0952 30.4959L48.417 24.9982Z" fill="#9280C1"></path><path d="M48.417 24.9982L44.0952 30.496L37.3103 28.0302L41.6321 22.5325L48.417 24.9982Z" fill="#B5A2BF"></path><path d="M46.8373 11.4746L48.432 17.7386L44.2355 14.0933L42.6409 7.8302L46.8373 11.4746Z" fill="#B5A2BF"></path><path d="M48.4319 17.7387L23.4337 31.4187L10.2515 32.6916L44.2355 14.0934L48.4319 17.7387Z" fill="#9280C1"></path><path d="M40.2888 40.7609L42.5759 50.536H42.5741L11.855 33.512L10.2515 32.6916L23.4337 31.4187V31.4196L40.2888 40.7609Z" fill="#8478CC"></path><path d="M44.0952 30.4961L50.1405 56.3352L43.6739 55.2276L42.5761 50.5361L40.289 40.7609L37.3103 28.0303L44.0952 30.4961Z" fill="#A191C4"></path><path d="M42.6408 7.8302L44.2355 14.0933L10.2515 32.6915L8.65771 26.4284L42.6408 7.8302Z" fill="#A191C4"></path></svg>
                                    Saven
                                </div>
                                {/* <div onClick={() => setDisplay(false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-sidebar"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"/><line x1="9" y1="3" x2="9" y2="21"/></svg>
                                </div> */}
                            </span>
                        }
                    </h1>
                    <div className='flex justify-center w-full'>
                        <form onSubmit={handleSubmit} className='flex-row flex w-full'>
                            <div className='flex flex-row items-center gap-4 focus:outline-none  bg-white rounded-xl px-4 py-2 search-shadow border'>
                                <svg className='stroke-gray-400' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                <input placeholder='Search' value={search} onChange={handleSearch} type="text" className="w-full focus:outline-none bg-transparent bg-white text-gray-400" />
                            </div>
                            {/* <button type="submit" className='rounded text-white bg-green-500'>Submit</button> */}
                        </form>
                    </div>
                </div>
                <div className='flex-none px-6'>
                    <TypeSection removeSearch={removeSearch} />
                </div>
                <div className='w-full flex flex-col flex-shrink min-h-0 pl-6'>
                    <div className='flex flex-row w-full justify-between items-center pr-6'>
                        <h1 className='text-lg font-bold'>
                            <Link
                                to='/lists'
                            >
                            lists
                            </Link>
                        </h1>
                        <ListModal>
                            <div className='flex justify-end'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                                </svg>
                            </div>
                        </ListModal>

                    </div>
                    <div className='overflow-scroll'>
                        <ListOfLists removeSearch={removeSearch} />
                    </div>
                </div>
                <div className='mt-auto border-t border-gray-200 text-gray-400 py-4 flex flex-none gap-4 flex-col'>
                    <div className='px-8 flex flex-row gap-4 text-sm items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <Link
                            to="/settings"    
                        >
                        Settings
                        </Link>
                    </div>
                </div>
            </div>

            <div className='w-[240px] px-8 h-full flex flex-col py-8 flex-shrink-0'></div>
            <div className={`${showSidebar ? 'sidebar-colour bg-black  w-full shrink-0 opacity-50' : 'hidden'} `} onClick={() => setShowSidebar(false)}>
                        fdsafsdafadsfasd
            </div>
        </div>)
    
}

const TypeSection = ({ removeSearch }) => {

    let location = useLocation()
    console.log(location.pathname)
    

    const {showSidebar, setShowSidebar} = useContext(SidebarContext);


    const highlightColor = 'text-purple-800'

    return (
        <ol className='text-base text-zinc-700 flex flex-col gap-1'>
                        {/* <li className={`${type === 'home' ? 'bg-stone-200 cursor-default' : 'cursor-pointer'} rounded-md p-2`} onClick={removeSearch}>
                            <Link
                                to="/home"    
                            >
                            <span className='flex flex-row gap-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>                                                Home
                            </span>
                            
                            </Link>
                        </li> */}
                        <li className={`sidebar-transition rounded-xl p-2 ${location.pathname === '/library' ? `bg-gray-100 cursor-default ${highlightColor}` : 'cursor-pointer'}`} onClick={removeSearch}>
                            <Link
                                to="/library"    
                            >
                            <span className='flex flex-row gap-3 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book"><path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path></svg>
                                library
                            </span>
                            
                            </Link>
                        </li>
                        <li className={`hover:text-amber-700 sidebar-transition cursor-pointer rounded-xl p-2 ${location.pathname === '/articles' && `bg-gray-100 cursor-default ${highlightColor}`}`} onClick={removeSearch}>
                            <Link to="/articles">
                                <span className='flex flex-row gap-3 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-feather"><path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"></path><line x1="16" y1="8" x2="2" y2="22"></line><line x1="17.5" y1="15" x2="9" y2="15"></line></svg>                                                    
                                articles
                                </span>
                            </Link>
                        </li>
                        <li className={`sidebar-transition cursor-pointer ${location.pathname === '/videos' ? `bg-gray-100 cursor-default ${highlightColor}` : 'cursor-pointer'} rounded-xl p-2`} onClick={removeSearch}>
                            <Link to="/videos">
                            <span className='flex flex-row gap-3 items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-youtube"><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon></svg>
                                videos
                            </span>
                            </Link>
                        </li>
                        <li className={`sidebar-transition cursor-pointer ${location.pathname === '/websites' ? `bg-gray-100 cursor-default ${highlightColor}` : 'cursor-pointer'} rounded-xl p-2`} onClick={removeSearch}>
                            <Link to="/websites">
                            <span className='flex flex-row gap-3 items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>                                            
                            websites
                            </span>
                            
                            </Link>
                        </li>
                        <li className={`sidebar-transition cursor-pointer ${location.pathname === '/twitter' ? `bg-gray-100 cursor-default ${highlightColor}` : 'cursor-pointer'} rounded-xl p-2`} onClick={removeSearch}>
                            <Link to="/twitter">
                            <span className='flex flex-row gap-3 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>                                            
                                twitter
                            </span>
                            
                            </Link>
                        </li>
                        <li className={`sidebar-transition cursor-pointer ${location.pathname === '/music' ? `bg-gray-100 cursor-default ${highlightColor}` : 'cursor-pointer'} rounded-xl p-2`} onClick={removeSearch}>
                            <Link to="/bookmarks/music">
                            <span className='flex flex-row gap-3 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-music"><path d="M9 18V5l12-2v13"></path><circle cx="6" cy="18" r="3"></circle><circle cx="18" cy="16" r="3"></circle></svg>
                                music
                            </span>
                            
                            </Link>
                        </li>
                    </ol>
    )
}

const ListItem = ({ item: list }) => {


    let listId = list.list_id
    let listColour = list.list_colour

    const {mutate: addTooList} = useAddToList()

    const addToList = (listId, bookmarkId) => {
        axios.post('/lists/add', {
            listId: listId,
            bookmarkId: bookmarkId
        }).then((res) => {
            if (res.status === 201) {
                toast.success("Bookmark added to list", {
                    position: 'top-right'
                })
            }
        })
    }


    let [dropped, setDropped] = useState(null)
    let ref = useRef()

    let { dropProps, isDropTarget } = useDrop({
        ref,
        async onDrop(e) {
            let item = e.items
                .filter((item) =>
                item.kind === 'text' && item.types.has('bookmarkId')
            );

            let bookmarkId = await item[0].getText('bookmarkId').then(data => data)
            addTooList({
                listId: listId, 
                bookmarkId: bookmarkId
            })

            setDropped(item.join('\n'));
        },

    });

    const {list: listContext} = useContext(ModalContext)

    const [, setSelectedList] = listContext


    return (
        <div
            {...dropProps}
            role="button"
            tabIndex={0}
            ref={ref}
            className={`droppable ${isDropTarget ? 'target' : ''}`}
        >
            <div 
            onClick={() => setSelectedList({listId, listColour, listTitle: list.list_name})}
            className={`flex flex-row items-center gap-4 px-2 py-1 rounded-xl ${isDropTarget && 'bg-gray-100'}`}>
                <div className={`w-2 h-2 rounded-sm bg-${listColour}-400`} >

                </div>
                {list.list_name}
            </div>
        </div>
    )
}

const ListList = ({ lists, removeSearch }) => {

    const [state, setState] = useState();


    const handleChange = (event) => {
        setState(event.target.value)
    }

    if (lists) {
        return (
            <>
                <ol className='overflow-scroll w-full flex-shrink'>
                    {lists && lists.map((item, index) => {
                        return (
                            <li key={item.list_id} onClick={removeSearch}>
                                <Link to={`/list/${item.list_id}`} state={{listTitle: item.list_name, listId: item.list_id, listColour: item.list_colour}}>
                                    <ListItem item={item}/>
                                </Link>
                            </li>
                        )
                    })}
                    
                </ol>
            </>
        )

    }

}



export default Sidebar