import { useQuery } from "@tanstack/react-query"
import axios from "../api/axios"

export const useImage = (imageID) => {
    const {isLoading, isError, error, data, isFetching} = useQuery([`${imageID}`], () => 
        axios
            .get(`/bookmarks/image/${imageID}`)
            .then(res => {
                // console.log(res.data)
                return res.data
            }),
        {
            staleTime: 840000
        }
    )

    return {isLoading, isError, error, data, isFetching}
}