import { useQuery } from '@tanstack/react-query'
import { Masonry } from 'masonic'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from '../../api/axios'
import Header from '../../components/layout/Header'
import MasonryLayout from '../../components/MasonryLayout'
import Main from '../../components/layout/Main'

const List = ({ lists }) => {

    const location = useLocation();
    const {listTitle} = location.state;
    // console.log(location.state)
    const {listId} = useParams()
    

    const {isLoading, data, isFetching, refetch} = useQuery(['list', listId], () =>
        axios
            .get(`/lists/${listId}`)
            .then(res => res.data)
    )

    // console.log(data) 
    
    if (isLoading) {
        return (
            <>
                <Header pageTitle={listTitle} isFetching={isFetching} refetch={refetch} listId={listId} list={true} />
            </>
        )
    }
    
    if (data) {
        return (
            <>
                {/* <button onClick={() => refetch()}>Click</button> */}
                <Header pageTitle={data.listName} refetch={refetch} isFetching={isFetching} listId={listId} list={true} listData={data} />
                <Main>
                    <MasonryLayout key={data.listId} data={data.bookmarks} lists={lists} />       
                </Main>
            </>
        )
    }
}

export default List