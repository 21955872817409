import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useState } from 'react'
// import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { useParams } from 'react-router-dom'
import axios from '../api/axios'
import { useAddNote } from '../hooks/useBookmarkData'

import MasonryLayout from '../components/MasonryLayout'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'



const NoteBoard = () => {

    const [noteText, setNoteText] = useState('')
    const [showButton, setShowButton] = useState(false)

    let {type} = useParams()

    const {mutate: addNote} = useAddNote(type)
    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log(URL)
        addNote(noteText)
        setNoteText('')
    }   

    return (
        <div onFocus={() => setShowButton(true)} onBlur={() => setShowButton(false)} className={`bg-white rounded tracking-widest holy note flex flex-col gap-1 overflow-hidden`}>
            <div className='p-4 w-full'>
                <span className='uppercase text-[#ff5925] text-xs'>Add A New Note</span>
                <textarea value={noteText} onChange={(event) => setNoteText(event.target.value)} className="focus:outline-none  resize-none w-full" placeholder='Start typing here...' name="note" id="note" rows="6"></textarea>
            </div>
            <button onClick={handleSubmit} className={`${showButton ? 'opacity-100' : 'opacity-0'} transition-opacity w-full bg-orange-500 text-white `}>SAVE</button>
        </div>
    )
}





export const Websites = ({ lists }) => {

    const {refetch, isLoading, isError, error, data, isFetching} = useQuery(['websites'], () => 
    axios
        .get(`/bookmarks/websites`)
        .then(res => {
            console.log(res.data)
            return res.data
        })
    )
    
    if (isLoading) {
        return (
            <>
                <Header pageTitle='websites' isFetching={isFetching} refetch={refetch} />
                
            </>
        )
    }
    
    if (data) {
        return (
            <>
                {/* <button onClick={() => refetch()}>Click</button> */}
                <Header pageTitle='websites' refetch={refetch} isFetching={isFetching} />
                <Main>
                    <MasonryLayout data={data} lists={lists} />       
                </Main>
            </>
        )
    }

    
    

    
    


   
    
}
