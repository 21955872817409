import Dropzone from 'react-dropzone'
import { useAddFile } from '../hooks/useBookmarkData'
import { useParams } from "react-router-dom";



export const MyDropzone = ({ children }) => {

    let {type} = useParams()


    const {mutate: addFile} = useAddFile(type)
    const handleFileSubmit = (acceptedFiles) => {
        addFile(acceptedFiles)
    }

    return (
        <Dropzone 
            onDrop={acceptedFiles => handleFileSubmit(acceptedFiles)}
            noClick
            name='test'
            type='file'
            >
        {({getRootProps, getInputProps}) => (
            <section>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {children}
                </div>
            </section>
        )}
        </Dropzone>

    )
}

export default Dropzone